import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"


export class Design {

    @action shopNSkinInstall = (data, cb) => {

        // let property = ['store_id', 'id', 'shopNo', 'skin_no', 'script_no', 'isSetScript']
        // let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'mutation',
            operation: 'shopNSkinInstall',
            variables: data,
            getData: 'data'
        };
        let gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
                if(typeof cb === 'function') {
                    cb(results);
                }
                else {
                    return results;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    @action designRunSetting = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'designRunSetting',
            variables: data,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                let result = res.data.data[params.operation];
                if(typeof cb === 'function') {
                    cb(result);
                }
                else {
                    return result;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


    @action getMainDisplayGroup = (data, cb) => {

        let params = {
            type: 'query',
            operation: 'getMainDisplayGroup',
            variables: data,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                let result = res.data.data[params.operation];
                if(typeof cb === 'function') {
                    cb(result);
                }
                else {
                    return result;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };
    @action setMainProperties = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'setMainProperties',
            variables: data,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                let result = res.data.data[params.operation];
                if(typeof cb === 'function') {
                    cb(result);
                }
                else {
                    return result;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };



    @action getMainProperties = (data, cb) => {

        let params = {
            type: 'query',
            operation: 'getMainProperties',
            variables: data,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                let result = res.data.data[params.operation];
                if(typeof cb === 'function') {
                    cb(result);
                }
                else {
                    return result;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


    //
    //
    // @action applyAllSkin = (data, cb) => {
    //
    //     let property = ['store_id']
    //     let variables = util.getDataStByKey(data, property);
    //
    //     let params = {
    //         type: 'mutation',
    //         operation: 'applyAllSkin',
    //         variables: variables,
    //         getData: 'data'
    //     };
    //     let gpqParams = gql(params);
    //
    //     axios.post('/', gpqParams)
    //         .then(function (res) {
    //             let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
    //             if(typeof cb === 'function') {
    //                 cb(results);
    //             }
    //             else {
    //                 return results;
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             return error;
    //         });
    // };

}
