import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'
import {
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup, Button, Col, Row, InputGroupText, FormRadio
} from "shards-react";
import {Link} from "react-router-dom";
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import moment from "moment";
import {ChromePicker} from "react-color";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {reactLocalStorage as Storage} from "reactjs-localstorage";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ widget }) => ({ updateWidget: widget.updateWidget, getWidgetList:widget.getWidgetList, removeWidget:widget.removeWidget }))
@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc }))

@observer
class metaWidget extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;


  @observable metaWidget = {
    store_id: null,
    productMetaType : "no",
    productRollingType : "no",
    reviewPageType : "both",
    reviewPagePath : "/board/구매후기/4/",
    reviewPageListCount: 5,
    reviewPagePhotoCount: 12,
    reviewPagePhotoTitle: ""
  };


  //list
  constructor(props) {
    super(props);
    this.init()


  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }
      this.permissions = this.props.me.permissions;

      this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id, shopNo:this.teamUser.selShopNo ? this.teamUser.selShopNo : 1}, function(res) {
        console.log(res);

        if(res.metaWidget) {
          _this.metaWidget = res.metaWidget;
        }
      });

    }

  };


  onChangeInputs = async (event, targetObj) => {
    let { name, value } = event.target;
    console.log(name, value)
    if(name && value){
      if(name === "reviewPageListCount" || name === "reviewPagePhotoCount") {
        targetObj[name] = parseInt(value);
      }
      else {
        targetObj[name] = value;
      }
    }
    else {
      targetObj[name] = value;
    }
  };


  updateMetaWidget = async (event, filter) => {

    let updateParams = {
      store_id: this.store._id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1
    };

    for(let i in filter) {
      let key = filter[i];
      updateParams[key] = this.metaWidget[key];
    }

    console.log(updateParams)

    // if(this.basicSettings.writer === '') {
    //   toast('답글 작성에 필요한 답글 작성자 이름을 입력해 주세요.');
    //   return;
    // }
    // else if(this.basicSettings.password === '') {
    //   alert('답글 작성에 필요한 비밀번호를 입력해 주세요.');
    //   return;
    // }

    this.props.updateDoc('updateMetaWidget', updateParams, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
    });
  }


  render() {
    let _this = this;



    return (
        <div className="main-content">
          <PageTitle/>

          <div className="widget-box">
            <div className="section-box">
              <div className="substitute-table"></div>
              <div className="section-tit">
                <h3>제품 리스트(카테고리) 페이지</h3>
              </div>

              <table className="s-table">
                <colgroup>
                  <col width="200px"/>
                  <col width=""/>
                  <col/>
                </colgroup>
                <tbody>
                <tr>
                  <th>리뷰 수/평점 노출</th>
                  <td colSpan={2} className="text-left">
                    <FormRadio
                        name="productMetaType"
                        value="no"
                        checked={!this.metaWidget.productMetaType || this.metaWidget.productMetaType === "no"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "productMetaType",
                              value: "no"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}
                    >
                      사용안함
                    </FormRadio>
                    <FormRadio
                        name="productMetaType"
                        value="both"
                        checked={this.metaWidget.productMetaType === "both"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "productMetaType",
                              value: "both"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}
                    >
                      리뷰 수 + 평점
                    </FormRadio>
                    <FormRadio
                        name="productMetaType"
                        value="count"
                        checked={this.metaWidget.productMetaType === "count"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "productMetaType",
                              value: "count"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}

                    >
                      리뷰 수
                    </FormRadio>
                    <FormRadio
                        name="productMetaType"
                        value="score"
                        checked={this.metaWidget.productMetaType === "score"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "productMetaType",
                              value: "score"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}
                    >
                      별점
                    </FormRadio>
                  </td>
                </tr>
                {/*<tr>
                  <th>리뷰 롤링</th>
                  <td colSpan={2} className="text-left">
                    <FormRadio
                        name="productRollingType"
                        value="no"
                        checked={!this.metaWidget.productRollingType || this.metaWidget.productRollingType === "no"}
                        onChange={event => this.onChangeInputs(event, this.metaWidget)}
                    >
                      사용안함
                    </FormRadio>
                    <FormRadio
                        name="productRollingType"
                        value="grade"
                        checked={this.metaWidget.productRollingType === "count"}
                        onChange={event => this.onChangeInputs(event, this.metaWidget)}

                    >
                      추천
                    </FormRadio>
                    <FormRadio
                        name="productRollingType"
                        value="grade"
                        checked={this.metaWidget.productRollingType === "count"}
                        onChange={event => this.onChangeInputs(event, this.metaWidget)}

                    >
                      평점
                    </FormRadio>
                    <FormRadio
                        name="productRollingType"
                        value="grade"
                        checked={this.metaWidget.productRollingType === "count"}
                        onChange={event => this.onChangeInputs(event, this.metaWidget)}

                    >
                      최신
                    </FormRadio>
                  </td>
                </tr>*/}
                </tbody>
              </table>

              <div className="btn-save">
                <Button className="save-button" onClick={event => this.updateMetaWidget(event, ["productMetaType", "productRollingType"])}>저장</Button>
              </div>

            </div>
          </div>

          <div className="widget-box">
            <div className="section-box">
              <div className="substitute-table"></div>
              <div className="section-tit">
                <h3>리뷰 페이지</h3>
              </div>

              <table className="s-table">
                <colgroup>
                  <col width="200px"/>
                  <col width="40%"/>
                  <col />
                </colgroup>
                <tbody>
                <tr>
                  <th>리뷰 페이지 주소</th>
                  <td colSpan={2} className="text-left">
                    <FormInput name="reviewPagePath" placeholder="/board/구매후기/4/" value={this.metaWidget.reviewPagePath}
                               onChange={(event) => {
                                 this.onChangeInputs(event, this.metaWidget)
                               }} className="table-input-text search-input"/>
                  </td>
                </tr>
                <tr>
                  <th>포토리뷰/리스트</th>
                  <td colSpan={2} className="text-left">
                    <FormRadio
                        name="reviewPageType"
                        value="both"
                        checked={!this.metaWidget.reviewPageType || this.metaWidget.reviewPageType === "both"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "reviewPageType",
                              value: "both"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}
                    >
                      포토리뷰 + 리스트
                    </FormRadio>
                    <FormRadio
                        name="reviewPageType"
                        value="list"
                        checked={this.metaWidget.reviewPageType === "list"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "reviewPageType",
                              value: "list"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}

                    >
                      리스트
                    </FormRadio>
                    <FormRadio
                        name="reviewPageType"
                        value="photo"
                        checked={this.metaWidget.reviewPageType === "photo"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "reviewPageType",
                              value: "photo"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}
                    >
                      포토리뷰
                    </FormRadio>
                    <FormRadio
                        name="reviewPageType"
                        value="no"
                        checked={this.metaWidget.reviewPageType === "no"}
                        onChange={() => {
                          let event = {
                            target: {
                              name: "reviewPageType",
                              value: "no"
                            }
                          }
                          this.onChangeInputs(event, this.metaWidget)
                        }}

                    >
                      사용안함
                    </FormRadio>
                  </td>
                </tr>
                <tr>
                  <th>리스트 페이지당 리뷰수 </th>
                  <td className="text-left">
                    <FormSelect value={this.metaWidget.reviewPageListCount} name="reviewPageListCount"
                                onChange={event => {
                                  this.onChangeInputs(event, this.metaWidget)
                                }}>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </FormSelect>
                  </td>
                  <td className="text-left"></td>
                </tr>
                <tr>
                  <th>포토리뷰 리뷰수</th>
                  <td className="text-left">
                    <FormSelect value={this.metaWidget.reviewPagePhotoCount} name="reviewPagePhotoCount"
                                onChange={event => {
                                  this.onChangeInputs(event, this.metaWidget)
                                }}>
                      <option value="3">3</option>
                      <option value="6">6</option>
                      <option value="9">9</option>
                      <option value="12">12</option>
                      <option value="15">15</option>
                      <option value="18">18</option>
                      <option value="21">21</option>
                    </FormSelect>
                  </td>
                  <td className="text-left">
                    {/*<FormCheckbox toggle small checked={this.metaWidget.reviewPagePhotoSlider === "yes"}*/}
                    {/*              name="reviewPagePhotoSlider"*/}
                    {/*              onChange={event => {*/}
                    {/*                this.metaWidget.reviewPagePhotoSlider = this.metaWidget.reviewPagePhotoSlider === "yes" ? "no": "yes";*/}
                    {/*              }}>*/}
                    {/*  {this.metaWidget.reviewPagePhotoSlider === "yes" ? '슬라이드' : '일반'}*/}
                    {/*</FormCheckbox>*/}
                  </td>
                </tr>
                <tr>
                  <th>포토리뷰 타이틀</th>
                  <td colSpan={2} className="text-left">
                    <FormInput name="reviewPagePhotoTitle" placeholder="" value={this.metaWidget.reviewPagePhotoTitle}
                               onChange={(event) => {
                                 this.onChangeInputs(event, this.metaWidget)
                               }} className="table-input-text search-input"/>
                  </td>
                </tr>

                {/*<tr>
                  <th>리뷰 롤링</th>
                  <td colSpan={2} className="text-left">
                    <FormCheckbox toggle small checked={this.metaWidget.productRollingType}
                                  onChange={event => this.onChangeChecked(event, this.metaWidget)}>
                      {this.metaWidget.isProductRolling ? '사용중' : '미사용중'}
                    </FormCheckbox>
                  </td>
                </tr>*/}


                </tbody>
              </table>

              <div className="btn-save">
                <Button className="save-button" onClick={event => this.updateMetaWidget(event, ["reviewPageType", "reviewPagePath", "reviewPageListCount", "reviewPagePhotoCount", "reviewPagePhotoSlider", "reviewPagePhotoTitle"])}>저장</Button>
              </div>

            </div>
          </div>

        </div>
    )
  }
}

export default metaWidget;
