import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link, Redirect} from 'react-router-dom';
import './style.scss'
import './cafe.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Button, FormCheckbox, FormGroup} from "shards-react";

@inject(({ teamUser }) => ({ oauthByCafe24: teamUser.oauthByCafe24, genTokenByCafe24: teamUser.genTokenByCafe24, authCheck:teamUser.authCheck, cafe24SignUp:teamUser.cafe24SignUp, signInJoinAfter: teamUser.signInJoinAfter }))
@observer
class Cafe extends Component {

  @observable cafe24Store = {};
  @observable teamUser = {
    userId: '',
    password: ''
  };
  @observable redirectInfo = {
    is: false,
    pathname: '/welcome'
  }
  @observable service = {
    agree: {
      serviceAgree: false,
      privateAgree: false,
    }

  }
  @observable nowStep = 0;
  @observable joinedTeamUser = false; //스토어는 가입X, 다른 스토어에 등록된 email이 있는 경우

  constructor(props) {
    super(props);
    // 1. /cafe24?is_multi_shop=T&lang=ko_KR&mall_id=swo815&nation=KR&shop_no=1&timestamp=1709707639&user_id=swo815&user_name=이신우&user_type=P&hmac=aHqeMGvojmqwIK9cpZXtM1Trmh2yjYx0bMFrruypnhQ%3D
    // 2. https://swo815.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=NpZpTrRuDFsmlOtG5ObwrD&state=2026,100,730&redirect_uri=https://ocadmin.onreview.co.kr/cafeRedirect&scope=mall.write_application,mall.read_application,mall.read_category,mall.read_product,mall.read_order,mall.write_community,mall.read_community,mall.read_store, mall.read_salesreport
    // 3. /cafeRedirect?code=Rgpz3RrCOFAmOfOaVwnAaA&state=2026,100,730
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams)
    if(searchParams.get('error')) {
      //https://orvad.owphew.co.kr/cafeRedirect?error=invalid_scope&error_description=The+scope+added+by+Cafe24+Developers+is+invalid.+Please+try+again.&state=2026,100,730&trace_id=8bd387a764f7de447a06065159eaebbb
      alert('인증실패');
      window.close();
    }
    else if(window.location.pathname == '/cafe24' && searchParams.get('mall_id') || !searchParams.get('code')) {
      this.oauthByCafe24(searchParams);
    }
    else if(window.location.pathname == '/cafeRedirect' && searchParams.get('code')) {
      this.genTokenByCafe24(searchParams);
    }
    else {
      alert('잘못된 접근 방식입니다. 문의 바랍니다.');
    }

  };

  @action
  async oauthByCafe24(searchParams) {
    let params = {};
    searchParams.forEach((value, key) => {
      if(key !== "auth_config") {
        params[key] = value;
      }
      //     {auth_config:"{\\MENU_AUTH\\:[],\\FUNCTION_AUTH\\:[]}",is_multi_shop:"T",lang:"ko_KR",mall_id:"chironeck",nation:"KR",shop_no:"1",timestamp:"1743658785",user_id:"onreple",user_name:"온리플",user_type:"A",hmac:"QG6OHqu0IySz6208l2SN2A5MxiFxX9DGadtABjaTYu0="} )  {data}}',


    });
    console.log(params)
    let res = await this.props.oauthByCafe24(params);
    let redirectUrl = res.redirectUrl;
    delete res.redirectUrl;
    Storage.remove('mallInfo');
    Storage.setObject('mallInfo', res);

    window.location.replace(redirectUrl);
  }

  async genTokenByCafe24(searchParams) {
    let _this = this;
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    console.log(params)
    this.props.genTokenByCafe24(params, function(res) {
      console.log(params)
      console.log(res)



      if(res.joinedTeamUser) {
        _this.joinedTeamUser = res.joinedTeamUser;
      }
      if(res.cafe24Store && res.cafe24Store._id) {
        //_this.service.emailId = res.cafe24Store.email;
        _this.service.cafe24InstalledId = res.cafe24Store._id;
        _this.nowStep = 1;
      }
      else if(res.teamUser && res.token) {
        _this.redirectInfo.is = true;
      }
      else {
        alert("접근이 잘못 되었습니다.");
        //window.location.replace('/');
      }

      if(res.cafe24Store) {
        _this.cafe24Store = res.cafe24Store;
      }


      //console.log(res)
      // window.location.replace('/');
      // if(res.emailId && res.token) {
      //   _this.redirectInfo.is = true;
      // }
      // else {
      //   console.log(res);
      // }


    });
  }

  nextStep = async (nextStep) => {
    if(nextStep == 3) {
      if(!this.service.agree.serviceAgree || !this.service.agree.privateAgree) {
        alert('정책에 모두 동의를 하셔야 합니다.');
        return false;
      }
      if(!this.cafe24Store || !this.cafe24Store.email) {
        alert('카페24 관리자 > 쇼핑몰설정 > 기본설정 > 내쇼핑몰정보 중 필수 사항을 입력 후 다시 실행 하여 주세요.');
        return false;
      }
    }
    this.nowStep = nextStep;
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.service[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  doCafe24SignUp() {
    let _this = this;
    if(!this.service.cafe24InstalledId) {
      alert("설정이 잘못 되었습니다.");
      //window.location.replace('/');//창 닫기
    }
    else if(!this.service.agree.serviceAgree || !this.service.agree.privateAgree) {
      alert('정책에 모두 동의를 하셔야 합니다.');
    }
    else if(!this.service.password) {
      alert('비밀번호를 입력 하셔야 합니다.')
    }
    else {
      this.props.cafe24SignUp(_this.service, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          if(res.hasStore) {
            alert('이미 가입 되어 있습니다.')
          }
          else {
            if(res.teamUser && res.token) {
              _this.props.signInJoinAfter(res.teamUser, function(res) {
                _this.redirectInfo.is = true;
              });
            }
            else {
              alert("처리가 잘못 되었습니다.");
              //window.location.replace('/');
            }
          }
        }
      });
    }

  }

  render() {
    return (
        <>
          {this.redirectInfo.is ? (
              <Redirect to={{pathname: this.redirectInfo.pathname}} />
          ) : (
              <>
                <div className="sign-content">
                  <div className="sign-content-bg">
                    <span className="bg-cont1"></span>
                    <span className="bg-cont2"></span>
                    <span className="bg-cont3"></span>
                    <span className="bg-cont4"></span>
                  </div>
                  <div className="sign-content-card">
                    {this.nowStep == 9 ? (
                        <div className="step_9">
                          <h3>잠시 점검 중입니다.</h3>
                          <p className="welcome-text">온리플 서비스 점검 중에 있습니다. 30분 후에 다시 실행 부탁드립니다.</p>
                        </div>
                    ) : null}
                    {this.nowStep == 1 ? (
                        <div className="step_1">
                          <h3>{this.cafe24Store.president_name}님 환영합니다.</h3>
                          <p className="welcome-text">온리플 서비스에 오신 것을 환영합니다😊<br/>{this.cafe24Store.company_name} 쇼핑몰과 함께할 수 있어 기쁜 마음입니다.<br/>멋진 경험을 함께 하는<br/>좋은 파트너가 될 수 있길 기원합니다.</p>
                          <div className="btn-box" >
                            <Button outline pill active theme="success" size="lg" onClick={event => this.nextStep(2)}>다음으로</Button>
                          </div>

                        </div>
                    ) : null}
                    {this.nowStep == 2 ? (
                        <div className="step_2">
                          <h3>서비스 이용을 위해 동의가 필요합니다.</h3>
                          <div className="sign-form">
                            <fieldset>
                              <div className="sign-form-box">
                                <div className="sign-form-box-wrap">
                                  <div className="sign-form-row">
                                    <div>
                                      <a href="/serviceAgree" target="_blank">이용약관 동의 보기</a>
                                    </div>
                                    <div>
                                      <FormGroup className="">
                                        <FormCheckbox toggle
                                                      name="serviceAgree"
                                                      checked={this.service.agree.serviceAgree || false}
                                                      onChange={event=> this.onChangeChecked(event, this.service.agree, ['serviceAgree'])} >
                                          동의하기
                                        </FormCheckbox>
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="sign-form-row">
                                    <div>
                                      <a href="/privateAgree" target="_blank">개인정보 이용약관 보기</a>
                                    </div>
                                    <div>
                                      <FormGroup className="">
                                        <FormCheckbox toggle
                                                      name="privateAgree"
                                                      checked={this.service.agree.privateAgree || false}
                                                      onChange={event=> this.onChangeChecked(event, this.service.agree, ['privateAgree'])} >
                                          동의하기
                                        </FormCheckbox>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className="btn-box" >
                            <Button outline pill active theme="light" size="lg" onClick={event => this.nextStep(1)}>이전</Button>
                            {!this.joinedTeamUser ?
                                (<Button outline pill active theme="success" size="lg" onClick={event => this.nextStep(3)}>다음으로</Button>) :
                                (<Button outline pill active theme="info" size="lg" onClick={event => this.doCafe24SignUp()}>시작하기</Button>) }
                          </div>
                        </div>
                    ) : null}
                    {this.nowStep == 3 ? (
                        <div className="step_3">
                          <h3>관리자 비밀번호 입력</h3>
                          <div className="sign-form">
                            <div className="sign-form-box">
                              <dl>
                                <td>로그인 ID : {this.cafe24Store.email}</td>
                              </dl>
                              <dl>
                                <dt><input name="password" type="password" className="" placeholder="비밀번호" value={this.service.password} onChange={event => this.onChangeValue(event)} /></dt>
                              </dl>
                            </div>
                          </div>
                          <div className="btn-box" >
                            <Button outline pill active theme="light" size="lg" onClick={event => this.nextStep(2)}>이전</Button>
                            <Button outline pill active theme="success" size="lg" onClick={event => this.doCafe24SignUp()}>시작하기</Button>
                          </div>
                        </div>
                    ) : null}

                    {/*<div className="btn-box">*/}
                    {/*  <input type="button" value="회원가입" className="save-button" onClick={event => this.doSignUp()}/>*/}
                    {/*  <div className="sign-in">*/}
                    {/*    <span>이미 계정이 있으신가요?</span><Link to={"/signIn"}><strong>로그인</strong></Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </>
          )}

        </>
    )
  }
}

export default Cafe;
