import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'
import {
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup, Button, Col, Row, InputGroupText
} from "shards-react";
import {Link} from "react-router-dom";
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import moment from "moment";
import {ChromePicker} from "react-color";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

const selectingProps = [
  {"key" : "newest", "label": "최신순"},
  {"key" : "grade", "label": "평점순"},
  // {"key" : "specify", "label": "지정"},
];

const displayAreaProps = [
  {"key" : "main", "label": "메인(인덱스) 페이지"},
  {"key" : "list", "label": "리스트 페이지"},
  {"key" : "detail_top", "label": "상세 페이지 상단"},
  {"key" : "board_top", "label": "후기 페이지 상단"},
  // {"key" : "detail_bottom", "label": "상세 페이지 하단"},
  {"key" : "code", "label": "코드로 사용"},
];

const colProps = [
  {"key" : 2, "label": 2},
  {"key" : 3, "label": 3},
  {"key" : 4, "label": 4},
  {"key" : 5, "label": 5},
  {"key" : 6, "label": 6},
];

const rowProps = [
  {"key" : 1, "label": 1},
  {"key" : 2, "label": 2},
  {"key" : 3, "label": 3},
];

const booleanProps = [
  {"key" : true, "label": "사용함"},
  {"key" : false, "label": "사용안함"},
];

const booleanDisProps = [
  {"key" : false, "label": "노출안함"},
  {"key" : true, "label": "노출"},
];

const defaultStyle = {
  marginTop: 30,
  marginBottom: 30,
}

const defaultHeadStyle =  {
  textAlign: 'left',
  color: '#000000',
  size: 23,
  marginTop: 30,
  marginBottom: 30
}



@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ widget }) => ({ updateWidget: widget.updateWidget, getWidgetList:widget.getWidgetList, removeWidget:widget.removeWidget }))
@inject(({ common }) => ({ updateDoc: common.updateDoc, getDoc: common.getDoc }))

@observer
class widgetList extends Component {

  @observable shopUser = {};
  @observable shopInfo = {};
  @observable emailId = '';
  @observable isLogin = false;

  @observable isShowMColor_1 = false;

  @observable makeWidgetProps = {
    "basic": {label: "일반 리뷰", selecting: selectingProps, displayArea: displayAreaProps, row: rowProps, col: colProps, isAutoPlay: booleanProps, isDisplay: booleanDisProps, isTitleDis: booleanDisProps},
    "slider": {label: "슬라이드 리뷰", selecting: selectingProps, displayArea: displayAreaProps, row: rowProps, col: colProps, isAutoPlay: booleanProps, isDisplay: booleanDisProps, isTitleDis: booleanDisProps},
    // "analysis": {label: "분석 위젯", selecting: selectingProps, displayArea: displayAreaProps, row: rowProps, col: colProps, isAutoPlay: booleanProps, isDisplay: booleanDisProps},
    // "ai_floating": {label: "AI 분석 위젯", selecting: selectingProps, isAutoPlay: booleanProps, isDisplay: booleanDisProps},
    // "media": {"label": "포토 리뷰", "selecting": selectingProps, "displayArea": displayAreaProps, "row": rowProps, "col": colProps, "isAutoPlay": booleanProps},
    // "movie": {"label": "영상 리뷰", "selecting": selectingProps, "displayArea": displayAreaProps, "row": rowProps, "col": colProps, "isAutoPlay": booleanProps},
    // "reviewList": {"label": "실시간 리뷰 목록 위젯"},
    //"buyAlam": {"label": "구입 알림 위젯"},
    // "reviewAlam": {"label": "리뷰 알림 위젯"},
    // "writableReview": {"label": "리뷰작성유도 위젯"},
  };

  @observable newWidgetProps = this.makeWidgetProps.basic;

  @observable newWidget = {};
  @observable curWidget = {
    style:{ style: defaultStyle },
    head:{ style: defaultHeadStyle },
    display: {
      rating: true,
      count: true
    }
  };

  //list
  @observable listParams = {
    page:1, limit:20,
  }
  @observable list = [];

  //modals
  @observable modals = {
    isNewWidget: false,
    isWidgetHeadSetiing: false
  };


  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }
      this.permissions = this.props.me.permissions;

      // this.props.getDoc('getDesignConfig', {store_id: this.teamUser.selStore_id}, function(res) {
      //   if(res.errors && res.errors.length > 0) {
      //     alert(res.errors[0].message);
      //   }
      //   else {
      //     let results;
      //     if(res && res.data) {
      //       results = res.data;
      //       //console.log(results);
      //       if(results.store_id) {
      //         _this.basicWidget = results.commons ? results.commons : _this.basicWidget;
      //         _this.display = results.display ? results.display : _this.display;
      //       }
      //     }
      //   }
      // });
      this.getBasicSettings();
      this.newWidget = {
        shopNo : this.teamUser.selShopNo ? this.teamUser.selShopNo :1,
        title: '',
        type: 'basic',
        selecting: 'newest',
        displayArea: 'main',
        col: 4,
        row: 1,
        isAutoPlay: 'true',
        style:{ style: defaultStyle },
        head:{ style: defaultHeadStyle }
      }
      this.listParams.store_id = this.teamUser.selStore_id;
      this.listParams.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo :1;
      this.getWidgetList();
    }
  };

  getBasicSettings = async() => {
    let _this = this;
    this.props.getDoc('getBasicSettings', {store_id: this.teamUser.selStore_id}, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        if(res && res.data) {
          _this.basicWidget = res.data;
        }
      }
    });
  };

  getWidgetList = async() => {
    if(this.listParams.store_id) {
      this.list = await this.props.getWidgetList(this.listParams);
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };


  onChangeValue = async (event, targetObj) => {
    const { name, value } = event.target;
    if(targetObj) {
      if(name === "isTitleDis") {
        targetObj[name] = value === "true";
      }
      else if(name === "rating" || name === "count") {
        targetObj[name] = value === "false";
      }
      else {
        targetObj[name] = value;
      }
    }
  };

  updateWidget = async () => {
    let _this = this;

    if(!_this.newWidget.title) {
      alert('제목을 입력해 주세요.');
      return false;
    }

    if(_this.newWidget.row) {
      _this.newWidget.row = parseInt(_this.newWidget.row);
    }
    if(_this.newWidget.col) {
      _this.newWidget.col = parseInt(_this.newWidget.col);
    }
    // if(_this.newWidget.type === 'slider') {
    //   _this.newWidget.dataPer = 2;
    // }

    let tempNewWidget = {..._this.newWidget}
    tempNewWidget.store_id = _this.teamUser.selStore_id;
    tempNewWidget.title = _this.newWidget.title;

    // for(let key in _this.newWidgetProps) {
    //   tempNewWidget[key] = _this.newWidget[key];
    // }



    if(!tempNewWidget.head) {
      tempNewWidget.head = {
        style: defaultHeadStyle
      }
    }
    if(!tempNewWidget.head.title) {
      tempNewWidget.head.title = tempNewWidget.title;
    }


    this.props.updateWidget(tempNewWidget, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        _this.modals.isNewWidget = false;
        _this.getWidgetList();
        //window.location.reload();
      }

    });
  };


  onChangeDataValue = async (item, event) => {
    const { name, value } = event.target;
    item[name] = value;
    let updateWidget = {
      id: item._id,
      store_id: this.teamUser.selStore_id
    }
    updateWidget[name] = item[name];

    if(updateWidget.row) {
      updateWidget.row = parseInt(updateWidget.row);
    }
    if(updateWidget.col) {
      updateWidget.col = parseInt(updateWidget.col);
    }
    if(updateWidget.dataPer) {
      updateWidget.dataPer = parseInt(updateWidget.dataPer);
    }
    // if(!item.isAutoPlay) {
    //   item.dataPer = 1;
    //   updateWidget['dataPer'] = item.dataPer;
    // }

    this.props.updateWidget(updateWidget, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('"' + item.title + '" 위젯 수정이 완료되었습니다.');
      }

    });
  };

  onChangeStyleValue = async (item) => {
    let _this = this;

    let updateWidget = {
      id: item._id,
      store_id: this.teamUser.selStore_id,
      isTitleDis: item.isTitleDis == true,
      style: item.style,
      head: item.head,
      display: item.display,
    };

    this.props.updateWidget(updateWidget, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('"' + item.title + '" 위젯 수정이 완료되었습니다.');
        _this.modals.isWidgetHeadSetiing = false;
      }

    });
  };

  // updateBasicWidget = async (target) => {
  //
  //   let data = this.basicWidget;
  //   data.store_id = this.teamUser.selStore_id;
  //   this.props.updateDoc('updateBasicSettings', data, function(res) {
  //     if(res.errors && res.errors.length > 0) {
  //       toast(res.errors[0].message);
  //     }
  //     else {
  //       toast('저장 되었습니다.');
  //     }
  //     //todo 페이지 새로 고침
  //   });
  // };



  onCopyWidgetCode = async (item) => {
    let htmlCode = '<div id="orvwig-' + item._id +'" widget_id="' + item._id +'" class="onreview-code-widget" style="display: none;"></div>';
    window.navigator.clipboard.writeText(htmlCode).then(() => {
      // 복사가 완료되면 이 부분이 호출된다.
      alert('코드가 복사 되었습니다. ( pc, 모바일 모두 사용가능합니다. )');
    });
  };

  onShowReviews = async (item) => {
    window.open('/reviewList?widget_id=' + item._id);
  };

  onRemoveWidget = async (item) => {
    let _this = this;

    let delWidget = {
      id: item._id,
      store_id: this.teamUser.selStore_id
    };

    this.props.removeWidget(delWidget, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        _this.getWidgetList();
      }

    });

  };

  editHeadModalOpen = async (item) => {
    if(!item.style) {
      item.style = defaultStyle;
    }
    if(!item.head) {
      item.head = {
        title : item.title,
        style : defaultHeadStyle
      }
    }
    if(!item.display) {
      item.display = {
        rating: true,
        count: true
      };
    }
    this.curWidget = item;
    this.modals.isWidgetHeadSetiing = true;
  };

  modalToggle = function(target, data) {
    if(this.isShowMColor_1) {
      this.isShowMColor_1 = false;
    }
    // this.modals[target] = !this.modals[target];
    // if(!target) {
    //   for( var i in this.modals) {
    //     this.modals[i] = false;
    //   }
    // }
    if(data) {
      this.modals.data = data;
    }
  };

  onChangeColor =  async (name, color, targetObj) => {
    targetObj[name] = color.hex;
    // this.showColorModalFn();
  };

  showColorModalFn =  async (targetModal) => {
    if(targetModal) {
      this[targetModal] = true;
    }
    else {
      this.isShowMColor_1 = false;
    }
  };

  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      //let limit = _this.list.limit;
      let totalPages, curPageNum, nextPage, prevPage, startNum, endNum;
      if (_this.list && _this.list.totalPages) {
        totalPages = _this.list.totalPages;
        curPageNum = _this.list.page;
        nextPage = _this.list.nextPage;
        prevPage = _this.list.prevPage;
        startNum = (parseInt((curPageNum - 1) / 10) * 10) + 1;
        endNum = startNum + 9 < totalPages ? startNum + 9 : totalPages;
      }
      let rendering = () => {
        for (let i = startNum; i <= endNum; i++) {
          if (i == curPageNum) {
            result.push(<li key={i} className="on"><a href={"?page=" + i}>{i}</a></li>);
          } else {
            result.push(<li key={i}><a href={"?page=" + i}>{i}</a></li>);
          }
        }
        return result;
      }
      if (_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                {curPageNum != 1 ?
                    <li className="btn-page-first"><a href="?page=1"><FeatherIcon icon="chevrons-left" className="nd-icon"/></a></li> : null}
                {startNum - 1 > 0 ?
                    <li className="btn-page-prev"><a href={'?page=' + (startNum - 1)}><FeatherIcon icon="chevron-left" className="nd-icon"/></a></li> : null}
                {rendering()}
                {endNum != totalPages ?
                    <li className="btn-page-next"><a href={'?page=' + (startNum + 10)}><FeatherIcon icon="chevron-right" className="nd-icon"/></a></li> : null}
                {curPageNum != totalPages ?
                    <li className="btn-page-last"><a href={'?page=' + totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon"/></a></li> : null}
              </ul>
            </div>);
      } else {
        return (<></>);
      }
    }

    return (
      <div className="main-content">
        <PageTitle />

        <div className="widget-box">

          <div className="section-box">
            <div className="detail-view-table">

              <div className="section-tit">
                <div className="table-sub-tit">
                  <span>다양한 페이지에서 추가 위젯을 사용해 보세요.</span>
                </div>
              </div>

              <Row className="mb-3">
                <Col className="text-right">
                  <button className="btn-widget-add" type='button' onClick={ event=> this.modals.isNewWidget = true } >위젯 추가
                    <FeatherIcon icon="plus" className="nd-icon" />
                  </button>
                </Col>
              </Row>

              <div className="widget-pick-list">
                <div className="pick-list-count">
                  총 <span>{this.list.totalDocs}</span>개
                </div>
              </div>

              <table>
                <colgroup>
                  <col width="13%"/>
                  <col width="10%"/>
                  <col width="10%"/>
                  <col width="9%"/>
                  <col width="12%"/>
                  <col width="7.5%"/>{/*라인당 리뷰 수*/}
                  <col width="7%"/>{/*라인수*/}
                  <col width="8%"/>
                  <col width="8%"/>{/*총 리뷰 노출수*/}
                  <col width=""/>
                </colgroup>
                <thead>
                <tr>
                  <th>제목</th>
                  <th>종류</th>
                  <th>노출 여부</th>
                  <th>리뷰 선택</th>
                  <th>노출 영역</th>
                  <th>라인당 리뷰 수</th>
                  <th>라인 수</th>
                  <th>자동슬라이드</th>
                  <th>리뷰 노출수</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {this.list && this.list.docs && this.list.docs.length > 0 ?
                  this.list.docs.map((item, i) => (
                      <tr key={i}>
                        <td className="left">{item.title ? item.title : item.type === 'ai_floating' ? 'AI 분석 플로팅 위젯' : '기본 위젯'}
                          {item.product_id && item.product_id.productName ? (
                              (() => {
                                    let link = "/productWidgetList?product_id=" + item.product_id._id;
                                    return (<><br/><a href={link}>{item.product_id.productName}</a></>);
                                  }
                              )()
                          ) : null}
                          {item.head && item.head.title ? (<div>노출명 : <span>{item.head.title}</span></div>) : null}
                        </td>
                        <td className="left">
                          <FormSelect name="type" value={item.type}
                                      disabled={item.type === 'ai_floating'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}>
                            {
                              Object.keys(this.makeWidgetProps).map((key, i) => (
                                  <option disabled={key !== "analysis" && item.type === "analysis"} key={key}
                                          value={key}>{this.makeWidgetProps[key].label}</option>
                              ))
                            }
                            {/*<option value='media'>포토+영상 리뷰</option>*/}
                            {/*<option value='movie'>영상 리뷰</option>*/}
                            {/*<option value='reviewList'>실시간 리뷰 목록 위젯</option>*/}
                            {/*<option value='reviewAlam'>실시간 구입, 리뷰 알림 위젯</option>*/}
                            {/*<option value='writableReview'>유효한 리뷰 작성 안내 위젯</option>*/}
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="isDisplay" value={item.isDisplay} onChange={event => {
                            this.onChangeDataValue(item, event)
                          }}>
                            {this.isUse ? <option value={true}>노출</option> :
                                <option value={true} disabled>노출</option>}
                            <option value={false}>노출안함</option>
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="selecting" value={item.selecting}
                                      disabled={item.selecting === 'ai' || item.selecting === 'analysis'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }
                                      }>
                            <option value='grade' disabled={item.selecting === 'analysis'}>평점순</option>
                            <option value='newest' disabled={item.selecting === 'analysis'}>최신순</option>
                            <option value='specify' disabled={item.selecting === 'analysis'}>지정</option>
                            {/*<option value='ai' disabled={item.selecting === 'ai'}>AI 리뷰 분석</option>*/}
                            {/*<option value='analysis'>분석</option>*/}
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="displayArea" value={item.displayArea}
                                      disabled={item.displayArea === "product_floating"}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}
                          >
                            <option disabled={item.selecting === 'analysis'} value='main'>메인(인덱스) 페이지</option>
                            <option disabled={item.selecting === 'analysis'} value='list'>리스트 페이지</option>
                            <option disabled={item.selecting === 'analysis'} value='detail_top'>상세 페이지 상단</option>
                            <option disabled={item.selecting === 'analysis'} value='board_top'>후기 페이지 상단</option>
                            {/*<option disabled={item.selecting === 'analysis'} value='detail_bottom'>상세 페이지 하단</option>*/}
                            {/*<option value='detail_modal'>상세 페이지 팝업</option>*/}
                            {/*<option value='product_floating'>상세 페이지 플로팅</option>*/}
                            <option value='code'>코드로 사용</option>
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="col" value={item.col}
                                      disabled={item.type === 'ai_floating'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="row" value={item.row}
                                      disabled={item.type === 'ai_floating'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </FormSelect>
                        </td>
                        <td className="left">
                          <FormSelect name="isAutoPlay" value={item.isAutoPlay}
                                      disabled={item.type != 'slider'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}>
                            <option value={true}>사용</option>
                            <option value={false}>사용안함</option>
                          </FormSelect>
                        </td>

                        <td className="left">
                          <FormSelect name="dataPer" value={item.dataPer}
                                      disabled={item.type != 'slider'}
                                      onChange={event => {
                                        this.onChangeDataValue(item, event)
                                      }}
                          >
                            <option value={1}>1 배수</option>
                            <option value={2}>2 배수</option>
                            <option value={3}>3 배수</option>
                          </FormSelect>
                        </td>

                        <td className="left widget-btn-box">
                          <FeatherIcon onClick={event => this.editHeadModalOpen(item)} icon="edit" className="nd-icon"/>
                          &nbsp;&nbsp;
                          {item.displayArea == 'code' ? (<Button theme="info" onClick={event => {
                            this.onCopyWidgetCode(item)
                          }}>코드복사</Button>) : null}
                          {item.selecting == 'specify' ? (<Button theme="info" onClick={event => {
                            this.onShowReviews(item)
                          }}>리뷰보기</Button>) : null}
                          <Button theme="danger" onClick={event => {
                            this.onRemoveWidget(item)
                          }}>삭제</Button>
                        </td>
                      </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={10}>생성된 위젯이 없습니다.</td>
                    </tr>
                }
                </tbody>
              </table>
              <Pagination/>
            </div>
          </div>
        </div>


        <Modal size="lg" className='isNewWidget' open={this.modals.isNewWidget} toggle={this.modalToggle.bind(this)}
               centered={true}>
          <ModalHeader>
            위젯 만들기
            <Button className="btn-modal-close" theme="secondary" size="sm" type='button'
                    onClick={event => this.modals.isNewWidget = false} >
              <FeatherIcon icon="x" className="nd-icon" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <div>
              <table>
                <colgroup>
                  <col style={{ width: '15%' }} />
                  <col />
                </colgroup>
                <tbody>
                {this.makeWidgetProps ?
                    <tr>
                      <th>종류</th>
                      <td>
                        <FormSelect name="type" value={this.newWidget.type} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            Object.keys(this.makeWidgetProps).map((key, i) => (
                                <option key={key} value={key}>{this.makeWidgetProps[key].label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                <tr>
                  <th>타이틀</th>
                  <td>
                    <FormInput name="title" placeholder="제목" value={this.newWidget.title} onChange={event => {
                      this.onChangeValue(event, this.newWidget)
                    }}/>
                  </td>
                </tr>
                <tr>
                  <th>타이틀 노출</th>
                  <td>
                    <FormSelect name="isTitleDis" value={this.newWidget.isTitleDis} onChange={event => {
                      this.onChangeValue(event, this.newWidget)
                    }}>
                      {
                        this.newWidgetProps.isTitleDis.map((item, key) => (
                            <option key={key} value={item.key}>{item.label}</option>
                        ))
                      }
                    </FormSelect>
                  </td>
                </tr>
                {this.newWidgetProps && this.newWidgetProps.selecting ?
                    <tr>
                      <th>리뷰 선택</th>
                      <td>
                        <FormSelect name="selecting" value={this.newWidget.selecting} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.selecting.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                {this.newWidgetProps && this.newWidgetProps.displayArea ?
                    <tr>
                      <th>노출 영역</th>
                      <td>
                        <FormSelect name="displayArea" value={this.newWidget.displayArea} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.displayArea.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                {this.newWidgetProps && this.newWidgetProps.col ?
                    <tr>
                      <th>라인당 리뷰수</th>
                      <td>
                        <FormSelect name="col" value={this.newWidget.col} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.col.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                {this.newWidgetProps && this.newWidgetProps.row ?
                    <tr>
                      <th>라인 수</th>
                      <td>
                        <FormSelect name="row" value={this.newWidget.row} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.row.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                {this.newWidgetProps && this.newWidgetProps.isAutoPlay && this.newWidget.type === "slider" ?
                    <tr>
                      <th>자동 슬라이드</th>
                      <td>
                        <FormSelect name="isAutoPlay" value={this.newWidget.isAutoPlay} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.isAutoPlay.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                {this.newWidgetProps && this.newWidgetProps.isDisplay ?
                    <tr>
                      <th>노출 여부</th>
                      <td>
                        <FormSelect name="isDisplay" value={this.newWidget.isDisplay} onChange={event => {
                          this.onChangeValue(event, this.newWidget)
                        }}>
                          {
                            this.newWidgetProps.isDisplay.map((item, key) => (
                                <option key={key} value={item.key}>{item.label}</option>
                            ))
                          }
                        </FormSelect>
                      </td>
                    </tr>
                    : null
                }
                </tbody>
              </table>
            </div>
            <div className="popup-close text-center mt-3">
              <Button type='button' onClick={event => this.updateWidget()}>저장</Button>
            </div>

          </ModalBody>
        </Modal>

        <Modal size="lg" className='isWidgetHeadSetiing' open={this.modals.isWidgetHeadSetiing} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>
            위젯 세부 설정
            <Button className="btn-modal-close" theme="secondary" size="sm" type='button' onClick={event=> this.modals.isWidgetHeadSetiing = false} >
              <FeatherIcon icon="x" className="nd-icon" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <div>
              <table>
                <colgroup>
                  <col style={{ width: '25%' }} />
                  <col />
                </colgroup>
                <tbody>
                <tr>
                  <th>위젯 이름</th>
                  <td>
                    <FormInput name="title" placeholder="제목" value={this.curWidget.title} onChange={event => {
                      this.onChangeValue(event, this.curWidget.title)
                    }}/>
                  </td>
                </tr>
                <tr>
                  <th>위젯 최대 너비</th>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput name="maxWidth" value={this.curWidget.style.maxWidth}
                                 placeholder="기본값은 100%입니다.(비율로 입력시 %를 붙여주세요.)" onChange={event => {
                        this.onChangeValue(event, this.curWidget.style)
                      }}/>
                      <InputGroupAddon type="append">
                        <InputGroupText>PX(%)</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th>상단 여백</th>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput name="marginTop" value={this.curWidget.style.marginTop}
                                 placeholder="기본값은 30px입니다." onChange={event => {
                        this.onChangeValue(event, this.curWidget.style)
                      }}/>
                      <InputGroupAddon type="append">
                        <InputGroupText>PX</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th>하단 여백</th>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput name="marginBottom" value={this.curWidget.style.marginBottom}
                                 placeholder="기본값은 30px입니다." onChange={event => {
                        this.onChangeValue(event, this.curWidget.style)
                      }}/>
                      <InputGroupAddon type="append">
                        <InputGroupText>PX</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th>타이틀</th>
                  <td>
                    <FormInput name="title" placeholder="타이틀" value={this.curWidget.head.title} onChange={event => {
                      this.onChangeValue(event, this.curWidget.head)
                    }}/>
                  </td>
                </tr>
                <tr>
                  <th>타이틀 노출 여부</th>
                  <td>
                    <FormSelect name="isTitleDis" value={this.curWidget.isTitleDis} onChange={event => {
                      this.onChangeValue(event, this.curWidget)
                    }}>
                      <option value={true}>노출함</option>
                      <option value={false}>노출안함</option>
                    </FormSelect>
                  </td>
                </tr>

                <tr>
                  <th>타이틀 텍스트 정렬</th>
                  <td>
                    <FormSelect name="textAlign" className="search-type" value={this.curWidget.head.style.textAlign}
                                onChange={event => {
                                  this.onChangeValue(event, this.curWidget.head.style)
                                }}>
                      <option value="">-정렬 선택하기-</option>
                      <option value="left">왼쪽 정렬</option>
                      <option value="center">가운데 정렬</option>
                      <option value="right">오른쪽 정렬</option>
                    </FormSelect>
                  </td>
                </tr>
                <tr>
                  <th>타이틀 폰트 크기</th>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput name="size" value={this.curWidget.head.style.size} placeholder="기본값은 23px입니다."
                                 onChange={event => {
                                   this.onChangeValue(event, this.curWidget.head.style)
                                 }}/>
                      <InputGroupAddon type="append">
                        <InputGroupText>PX</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th>타이틀 폰트 색</th>
                  <td>
                    <div className="color-picker">
                      {this.isShowMColor_1 ? (
                          <div className="color-picker-selecter-wrap"
                               onMouseLeave={event => this.isShowMColor_1 = false}>
                            <ChromePicker
                                name="color"
                                disableAlpha={true}
                                color={this.curWidget.head.style.color}
                                onChangeComplete={(color) => {
                                  this.onChangeColor('color', color, this.curWidget.head.style)
                                }}
                            />
                          </div>) : null}
                      <InputGroup>
                        <InputGroupAddon type="append">
                          <InputGroupText onClick={event => {
                            _this.showColorModalFn('isShowMColor_1');
                          }} style={{backgroundColor: this.curWidget.head.style.color}}>
                            <FeatherIcon icon="droplet" size="15" style={{color: "#ffffff"}}/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput value={this.curWidget.head.style.color} placeholder="색상값을 입력해 주세요." readOnly={true}
                                   onClick={event => {
                                     _this.showColorModalFn('isShowMColor_1');
                                   }}/>
                      </InputGroup>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>타이틀과 리뷰 리스트 간격</th>
                  <td>
                    <InputGroup className="mb-2">
                      <FormInput name="marginBottom" value={this.curWidget.head.style.marginBottom}
                                 placeholder="기본값은 30px입니다." onChange={event => {
                        this.onChangeValue(event, this.curWidget.head.style)
                      }}/>
                      <InputGroupAddon type="append">
                        <InputGroupText>PX</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th>리뷰 평점 / 카운트 노출</th>
                  <td>
                    <label><input onChange={event => {
                      this.onChangeValue(event, this.curWidget.display)
                    }} value={Boolean(this.curWidget.display.rating)}
                                                                 checked={Boolean(this.curWidget.display.rating)}
                                                                 name="rating"
                                                                 type="checkbox"/><span>&nbsp;평점</span></label>
                    <label className="ml-3"><input onChange={event => {
                      this.onChangeValue(event, this.curWidget.display)
                    }} value={Boolean(this.curWidget.display.count)}
                                                                 checked={Boolean(this.curWidget.display.count)}
                                                                 name="count"
                                                                 type="checkbox"/><span>&nbsp;카운트</span></label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="popup-close text-center mt-3">
              <Button type='button' onClick={event => this.onChangeStyleValue(this.curWidget)}>저장</Button>
            </div>

          </ModalBody>
        </Modal>


      </div>
    )
  }
}

export default widgetList;
