import React, {Component} from 'react';
import {decorate, observable, action, isObservableObject, toJS} from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Papa from 'papaparse';
import _ from "underscore";
import { srcToWebP } from 'webp-converter-browser'
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import ModalLoading from "../../../components/common/ModalLoading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeatherIcon from 'feather-icons-react';
import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Col, Row, Card, CardHeader, CardBody
} from "shards-react";
import moment from "moment";
import axios from "axios";
import {reactLocalStorage as Storage} from "reactjs-localstorage";

@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, base64UploadImages: common.base64UploadImages, cafe24UploadFiles: common.cafe24UploadFiles, uploadImg: common.uploadImg, removeMedia: common.removeMedia, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ product }) => ({ getProductList: product.getProductList, setProductSync: product.setProductSync, updateProductReplay: product.updateProductReplay }))
@inject(({ review }) => ({ updateReview:review.updateReview, uploadReviewExImgs:review.uploadReviewExImgs, updateMassReview:review.updateMassReview, getAnalysisList:review.getAnalysisList, genAIReviewAnalysis:review.genAIReviewAnalysis }))
@inject(({ AOA }) => ({ adminCusData:AOA.adminCusData }))

@observer
class ReviewMassWrite extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //list
  @observable listParams = {
    page:1, limit:100000,
  }
  @observable searchParams = {
    keyword: '',
    overReviewCount: '',
  };
  @observable list = [];
  @observable goodList = [];
  @observable exImgList = {};

  //modals
  @observable modals = {
    data: null,
    isWriteForm: false,
    widgetData: {},
    isMakeWidgetModal: false
  };

  @observable widgetUrl;
  @observable categories = [];
  @observable newReview = {};
  @observable isShowCollapse = false;
  @observable isUploading = false;

  constructor(props) {
    super(props);
    this.init()
  }


  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.permissions = this.props.me.permissions;

      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }

      if(this.store.presidentName !== "윤정진") {
        let isConfirm = window.confirm("해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.")
        if(!isConfirm) {
          window.history.back();
        }
      }


      this.listParams.store_id = this.teamUser.selStore_id;
      this.listParams.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo : 1;
      //this.listParams.teamUserId = this.teamUser.id;

      //url params
      for (const key of this.urlParams.keys()) {
        if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
          this.listParams[key] = parseInt(this.urlParams.get(key));
        }
        else {
          this.searchParams[key] = this.urlParams.get(key);
        }
      }

      this.getProductList();
    }

  };


  getProductList = async() => {
    if(this.listParams.store_id) {

      let tempGoodList = await Storage.getObject('goodList');
      //let tempGoodList = {};
      if(!tempGoodList.totalDocs || tempGoodList.totalDocs === 0) {
        this.listParams.searchParams = this.searchParams;
        tempGoodList = await this.props.getProductList(this.listParams);
        Storage.setObject('goodList', tempGoodList);
      }
      this.goodList = _.indexBy(tempGoodList.docs, 'platformProductId');
      console.log(this.goodList)

      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };


  recombinationData = async (targetModel, otherModel, arrayData, addData) => {
    let reData = [];
    let platform = addData.platform ? addData.platform : (addData.fromWrite ? addData.fromWrite : '');
    for(let i in arrayData) {
      let row = arrayData[i];

      if(!row.parent_article_no) {
        let product_id, userOrder_id;
        let newRow = {
          exReview_id: row.review_id,
          shopNo: row.shop_no,
          platform:platform,
          syncFrom:platform,
          fromWrite: addData.fromWrite ? addData.fromWrite : platform,
        };


        for(let j in targetModel) {
          let myKey = targetModel[j];
          let otherKey = otherModel[myKey];

          if(myKey) {
            if(myKey === 'product_id'){
              newRow[myKey] = product_id;
            }
            if(myKey === 'userOrder_id' && userOrder_id) {
              newRow[myKey] = userOrder_id;
            }
            // if(myKey === 'platformProductId') {
            //   newRow[myKey] = platformProductId;
            // }
            // else if(myKey === 'platformData') {
            //   newRow[myKey] = row;
            //   if(addData.commentsObj && addData.commentsObj[row.article_no]) {
            //     newRow.comment = addData.commentsObj[row.article_no];
            //   }
            // }
            else if(myKey === 'platformImages') {
              if(row[otherKey] && row[otherKey].length > 0) {
                newRow[myKey] = row[otherKey];
                newRow["reviewType"] = "image";
              }
              else if(row['attach_file_urls'] && row['attach_file_urls'].length > 0) {
                newRow[myKey] = row['attach_file_urls'];
                newRow["reviewType"] = "image";
              }

            }
            else if(myKey === 'images') {
              newRow[myKey] = [];
              for(let i in otherKey) {
                if(row[otherKey[i]]) {
                  newRow[myKey].push({url: row[otherKey[i]]});
                }
              }
            }
            else if(myKey === 'movies') {
              newRow[myKey] = [];
              for(let i in otherKey) {
                if(row[otherKey[i]]) {
                  newRow[myKey].push({url: row[otherKey[i]]});
                }
              }
            }
            else if(myKey === 'isGuest') {
              newRow[myKey] = (!row['member_id']);
            }
            else if(myKey === 'isMember') {
              if(platform === 'nhnc') {
                newRow[myKey] = !!(row['member_id']);
              }
              else if(platform === 'cafe24') {

                newRow[myKey] = !!(row['member_id']);
              }
            }
            else if(addData[myKey]) {
              newRow[myKey] = addData[myKey];
            }
            else if(row[otherKey]) {

              if(myKey === 'isSecret' || myKey === 'isShow' || myKey === 'isMobile' || myKey === 'isAdult' || myKey === 'hasStock' || myKey === 'isDelete') {
                if(myKey === 'isMobile') {
                  newRow[myKey] = (row[otherKey] === 'M');
                }
                else {
                  newRow[myKey] = (row[otherKey] === 'T');
                }
              }
              else if(myKey === 'goodsPt') {
                newRow[myKey] = row[otherKey] * 20;
              }
              else if(myKey === 'contents') {
                newRow[myKey] = row[otherKey] ? row[otherKey].replace(/<\/?p>/gi, "\n") : "";
              }
              else if(myKey === 'price') {
                newRow[myKey] = row[otherKey] > 0 ?  row[otherKey] : row['price'];
              }
              else if(myKey === 'writerName') {
                newRow[myKey] = row[otherKey];
              }

              else {
                newRow[myKey] = row[otherKey];
              }
            }
            else {

              if(myKey === 'writerName') {
                newRow[myKey] = "-";
              }
              //console.log(myKey)
            }
          }
          else {
            //console.log(myKey)
          }

        }
        newRow.product_id = platform + '_' + newRow.store_id + '_' + newRow.platformProductId;
        if(newRow.platformProductId_ref) {
          newRow.platformProductId_ref += newRow.platformProductId;
          newRow._id = newRow.platformProductId_ref;
        }
        reData.push(newRow);
      }
    }

    this.isLoading = false;
    this.list = reData
    this.exImgList = _.filter(reData, function(item) {
      return item.images && item.images.length > 0;
    });
    console.log(this.exImgList)
  }

  vreviewToData = async (data) => {
    // store_id: this.teamUser.selStore_id,
    //     shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
    let myReview = ['store_id', 'platformArticleNo', 'shopNo', 'product_id', 'userOrder_id', 'platformProductId', 'platformOrderId', 'fromWrite', 'platformData', 'platformImages', 'platformUserId', 'writerName', 'writerId', 'writerEmail', 'writerPw', 'writerIp', 'subject', 'contents', 'images', 'movies', 'isSecret', 'isShow', 'isMobile',
      'hit', 'memoCnt', 'goodsPt', 'point', 'pointReason', 'payMileageStatus', 'payBonusMileageStatus', 'orderStatus', 'deliveryPt', 'isMember', 'isGuest', 'isAdmin', 'isDelete', 'uploadPath', 'writerAt', 'displayAt'];

    // this.newReview = {
    //   store_id: this.teamUser.selStore_id,
    //   shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
    //   teamUser_id: this.teamUser.id,
    //   product_id: '',
    //   platform: this.store.platform,
    //   orderProductOptions: '',
    //   displayStatus: 2,
    //   goodsPt: 100,
    //   isSecret: false,
    //   isShow: true,
    //   isMobile: false,
    //   isDelete: false,
    //   images: [],
    //   movies: [],
    //   isGuest: false,
    //   isAdmin: true,
    //   isMember: true,
    //   writerName: '',
    //   writerPw: this.teamUser.emailId,
    //   subject: '',
    //   contents: '',
    //   fromWrite: '일반리뷰',
    //   createdAt: moment()._d,
    //   displayAt: moment()._d
    // }

    let vreview = {
      userOrder_id: 'order_id',
      platformProductId: 'product_id',
      platformUserId: 'user_id',
      platformOrderId: 'order_id',
      // platformImages: 'image_url0',
      writerName: 'author_name',
      writerId: 'user_id',
      writerAt: 'created_at',
      displayAt: 'created_at',
      subject: 'title',
      contents: 'text',
      goodsPt: 'rating',
      mileage: 'reward_amount',
      images: ['image_url0', 'image_url1', 'image_url2', 'image_url3'],
      //movies: ['video_url0', 'video_url1'],
    }
    let addData = {
      store_id: this.teamUser.selStore_id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
      platform: this.store.platform,
      fromWrite: "file",
      displayStatus: 2,
      isSecret: false,
      isShow: true,
      isMobile: false,
      isDelete: false,
      deliveryPt: 100,
      compContents: "배송은 최고예요 😍",

      isGuest: false,
      isAdmin: true,
      writerPw: this.teamUser.emailId,
      createdAt: moment()._d,
      displayAt: moment()._d
    }
        // [
        // "review_id",
        //     "author_name",
        //     "user_id",v
        //     "order_id",
        //     "product_id",
        //     "product_name",
        //     "title",
        //     "text",
        //     "rating",
        //     "created_at",
        //     "reward_amount",
        //     "helpful_count",
        //     "upload_from",
        //     "question_answers",
        //     "selected_options",
        //     "video_url0",
        //     "video_url1",
        //     "image_url0",
        //     "image_url1",
        //     "image_url2"
        // ]
    this.recombinationData(myReview, vreview, data, addData)
  };

  handleFileUpload  = async (event) => {
    let _this = this;
    this.isLoading = true;
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // 첫 행을 헤더로 인식
        skipEmptyLines: true, // 빈 줄 제외
        complete: (result) => {
          console.log(result);
          _this.vreviewToData(result.data)
          // _this.list = result.data;
        },
        error: (error) => {
          console.error('CSV 파싱 오류:', error);
        },
      });
    }
  };

  uploadReviewExImgs = async() => {
    let _this = this;
    if(this.exImgList && this.exImgList.length > 0) {

      let updateData = {
        store_id: this.store._id,
        shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
        reviews: this.exImgList
      }
      // console.log('updateMassReview', updateData);
      this.props.uploadReviewExImgs(updateData, function(res) {
        _this.exImgList = res.data;

        _.each(_this.exImgList, function(item) {
          var targetItem = _.findWhere(_this.list, { exReview_id: item.exReview_id });
          if (targetItem) {
            targetItem.images = item.images;
            targetItem.platformImages = item.images;
          }
        });

      });

    }


    // // 2. filteredData의 platformImages 값 수정
    // _.each(filteredData, function(item) {
    //   item.platformImages = item.platformImages + "_modified";
    // });
    //
    // // 3. 원본 data에 filteredData 값 반영
    // _.each(data, function(item) {
    //   var updatedItem = _.findWhere(filteredData, { id: item.id });
    //   if (updatedItem) {
    //     item.platformImages = updatedItem.platformImages;
    //   }
    // });

  }


  updateMassReview = async() => {
    let _this = this;
    if(!this.isUse) {
      alert('유료회원 전용 기능입니다.');
      return false;
    }
    let updateData = {
      store_id: this.store._id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
      reviews: this.list
    }
    // console.log('updateMassReview', updateData);
    this.props.updateMassReview(updateData, function(res) {
      console.log(res);

    });
  }

  adminCusData = async() => {
    let _this = this;

    let updateData = {
      store_id: this.store._id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
    }
    // console.log('updateMassReview', updateData);
    this.props.adminCusData(updateData, function(res) {
      console.log(res);

    });
  }



  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      let limit = _this.list.limit;
      let totalPages = _this.list.totalPages;
      let curPageNum = _this.list.page;
      let nextPage = _this.list.nextPage;
      let prevPage = _this.list.prevPage;
      let startNum = (parseInt((curPageNum-1)/10)*10) +1;
      let endNum = startNum+9 < totalPages ? startNum+9 : totalPages;

      let urlParamsArr = new URLSearchParams(window.location.search);
      urlParamsArr.delete('page');
      let urlParams = '?' + urlParamsArr.toString();

      let rendering = () => {
        for(let i = startNum; i <= endNum; i ++) {
          if(i == curPageNum) {
            result.push(<li key={i} className="on"><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
          else {
            result.push(<li key={i}><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
        }
        return result;
      }

      if(_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                { curPageNum != 1 ? <li className="btn-page-first"><a href={urlParams + "&page=1"}><FeatherIcon icon="chevrons-left" className="nd-icon" /></a></li> : null}
                { startNum - 1 > 0 ? <li className="btn-page-prev"><a href={urlParams + "&page="+(startNum-1)}><FeatherIcon icon="chevron-left" className="nd-icon" /></a></li> : null}
                {rendering()}
                { endNum != totalPages  ? <li className="btn-page-next"><a href={urlParams + "&page="+(startNum+10)}><FeatherIcon icon="chevron-right" className="nd-icon" /></a></li> : null}
                { curPageNum != totalPages ? <li className="btn-page-last"><a href={urlParams + "&page="+totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon" /></a></li> : null}
              </ul>
            </div>);
      }
      else {
        return (<></>);
      }

    }

    return (
        <div className="main-content">
          <PageTitle />
          <Row  className="mtb-10">
            <Col xs={12}><Button onClick={event => this.adminCusData()}>커스텀</Button></Col>
          </Row>
          <p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>
          {/*<Row className="mtb-10 text-right">*/}
          {/*  <Col sm={{ size: 2, order: 2, offset: 10 }} >*/}
          {/*    <Button size="sm" onClick={event=> this.setProductSync()}><FeatherIcon size="17" icon="download" className="nd-icon" />&nbsp;&nbsp;상품정보 가져오기</Button>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <div className="review-write-box">
            <Card className="mb-4">
              <CardHeader>CSV 파일 업로드</CardHeader>
              <CardBody>
                <input
                    type="file"
                    accept=".csv"
                    onChange={event => this.handleFileUpload(event)}
                    className="p-2 border rounded-lg"
                />
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardHeader>1. 외부 리뷰 이미지 가져오기</CardHeader>
              <CardBody>
                <Row  className="mtb-10">
                  <Col xs={12}><Button onClick={event => this.uploadReviewExImgs()}>이미지 가져오기</Button></Col>
                </Row>
                <table>
                  <colgroup>
                    <col width="15%" />
                    <col width="15%" />
                    <col width="40%" />
                    <col width="20%" />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>상품</th>
                    <th>첨부</th>
                    <th>내용</th>
                    <th>작성자</th>
                    <th>평점</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/*짱구판 쇼미더머니라는 별칭이 있는 짱구 랩배틀 시리즈 유리의 랩배틀 실력을 인정한 그 장면 기억 하시나요? 너무 귀엽네요*/}
                  {this.exImgList && this.exImgList.length > 0 ?
                      this.exImgList.map((item, i) => (
                          <tr key={i}>
                            {/*<td>{this.goodList[item.product_id].images.s_2}</td>*/}
                            <td>
                              {this.goodList[item.platformProductId] ? (
                                  <img style={{width: "150px", height:"150px"}} src={this.goodList[item.platformProductId].images.s_2} />
                              ) : null}
                              <p>{this.goodList[item.platformProductId] ? this.goodList[item.platformProductId].productName : item.platformProductId + " - 상품정보 없음"}</p>
                            </td>
                            <td>
                              {item.movies[0] ? (
                                  <video controls width="150">
                                    <source src={item.movies[0].url} type="video/webm" />
                                  </video>
                              ) : null}
                              {item.images[0] ? (
                                  <img style={{width: "150px", height:"150px"}} src={item.images[0].url} />
                              ) : null}
                            </td>
                            <td className="text-left">{item.contents}</td>
                            <td>{item.writerName}</td>
                            <td>{item.goodsPt}</td>
                          </tr>
                      ))
                      :
                      <tr>
                        <td></td>
                      </tr>
                  }
                  </tbody>
                </table>
              </CardBody>
            </Card>


            <div className="section-box review-write-box">
              <Button onClick={event => this.updateMassReview()}>등록하기</Button>
              <div className="product-list-box">
                <div className="product-pick-list">
                  {/*<div className="pick-list-count">*/}
                  {/*  총 <span>{this.list && this.list.totalDocs ? this.list.totalDocs : 0}</span>개*/}
                  {/*</div>*/}
                </div>
                <table>
                  <colgroup>
                    <col width="15%" />
                    <col width="15%" />
                    <col width="40%" />
                    <col width="20%" />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>상품</th>
                    <th>내용</th>
                    <th>첨부</th>
                    <th>작성자</th>
                    <th>평점</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/*짱구판 쇼미더머니라는 별칭이 있는 짱구 랩배틀 시리즈 유리의 랩배틀 실력을 인정한 그 장면 기억 하시나요? 너무 귀엽네요*/}
                  {this.list && this.list.length > 0 ?
                      this.list.map((item, i) => (
                          <tr key={i}>
                            {/*<td>{this.goodList[item.product_id].images.s_2}</td>*/}
                            <td>
                              {this.goodList[item.platformProductId] ? (
                                  <img style={{width: "150px", height:"150px"}} src={this.goodList[item.platformProductId].images.s_2} />
                              ) : null}
                              <p>{this.goodList[item.platformProductId] ? this.goodList[item.platformProductId].productName : item.platformProductId + " - 상품정보 없음"}</p>
                            </td>
                            <td>
                              {item.movies[0] ? (
                                  <video controls width="150">
                                    <source src={item.movies[0].url} type="video/webm" />
                                  </video>
                              ) : null}
                              {item.images[0] ? (
                                  <img style={{width: "150px", height:"150px"}} src={item.images[0].url} />
                              ) : null}
                            </td>
                            <td className="text-left">{item.contents}</td>
                            <td>{item.writerName}</td>
                            <td>{item.goodsPt}</td>
                          </tr>
                      ))
                      :
                      <tr>
                        <td></td>
                      </tr>
                  }
                  </tbody>
                </table>
                {/*<div className="btn-area">*/}
                {/*  <button className="btn-file-upload">파일 업로드</button>*/}
                {/*</div>*/}
                {/*<Pagination />*/}
              </div>
            </div>
          </div>
          
          <ModalLoading isLoading={this.isLoading} />
        </div>
    )
  }
}

export default ReviewMassWrite;
