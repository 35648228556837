import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "underscore";
import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    FormSelect,
    FormInput,
    Button,
    FormCheckbox,
    FormTextarea,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormRadio
} from "shards-react";
//https://designrevision.com/docs/shards-react/getting-started
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ChromePicker} from "react-color";
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import moment from "moment";
import ModalLoading from "../../../components/common/ModalLoading";


@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, getMe:teamUser.getMe }))
@inject(({ company }) => ({ updateStore:company.updateStore, getApplySkins:company.getApplySkins, getApplyShop:company.getApplyShop, applyAllSkin:company.applyAllSkin, applySkin:company.applySkin}))
@inject(({ design }) => ({ shopNSkinInstall: design.shopNSkinInstall, getMainDisplayGroup:design.getMainDisplayGroup, getMainProperties:design.getMainProperties, setMainProperties:design.setMainProperties, designRunSetting:design.designRunSetting }))

@observer
class DesignSetting extends Component {

    //basic
    @observable teamUser = {};
    @observable permissions = {}
    @observable store = {};
    @observable company = {};
    @observable isLogin = false;
    @observable isLoading = false;

    //page

    @observable store = {};
    @observable shops = [];
    @observable selSettingValue = {
        productDisplay: 0
    };
    @observable getProductDisplayData = {};



    constructor(props) {
        super(props);
        this.init()
    }


    @action
    init = async (key) => {
        let _this = this;
        if(this.props.isLogin) {
            //basic
            this.isLogin = this.props.isLogin;
            this.teamUser = this.props.me;
            this.store = this.teamUser.store;
            this.permissions = this.props.me.permissions;
            this.getApplyShop(false);
            this.getMainDisplayGroup();
            this.getProductDisplayData = await this.getDesignData('https://orpapi.onreview.co.kr/design/product_display.json');
            console.log(this.getProductDisplayData);
        }
        // 멀티샵의 스킨 활성화 - 해당 쇼핑몰의 파일 업로드 -> 업로드 파일 링크 연결
        //

    };

    getApplyShop = async (actSync) => {
        let _this = this;
        let updateStore = {
            store_id: this.teamUser.store._id
        }
        this.props.getApplyShop( updateStore, function(res) {
            if(res.errors && res.errors.length > 0) {
                toast(res.errors[0].message);
            }
            else {
                _this.shops = res.data;
                if(actSync) {
                    toast('업데이트가 완료 되었습니다.');
                }
            }
        });
    }

    getMainDisplayGroup = async () => {
        try {
            let res = await this.props.getMainDisplayGroup({store_id: this.store._id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1});
            console.log(res)
        } catch (error) {
            console.error('getMainDisplayGroup:', error);
            return null;
        }
    }

    getDesignData = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data; // 변수에 할당하여 반환
        } catch (error) {
            console.error('getDesignData:', error);
            return null;
        }
    }


    onChangeShop = async (event) => {
        const { name, value } = event.target;
        let shopNo = value ? parseInt(value) : 1;
        this.store.shopNo = shopNo;
    };

    onChangeSetType = async (event, targetObj) => {
        const { name, value } = event.target;
        if(targetObj) {
            targetObj[name] = value;
        }
    };

    getSetting = async (type) => {
        let updateSettingData = {
            type : type,
            dataSet : {}
        }
        let res;
        switch (type) {
            case 'productDisplay':
                res = await this.props.getMainProperties({store_id: this.store._id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1});
                console.log(res.data)
                break;
        }

        // let res = await this.props.shopNSkinInstall({});
        // console.log(res);
    };

    setMainProperties = async (type) => {
        let updateSettingData = {
            type : type,
            dataSet : {}
        }
        let res;
        switch (type) {
            case 'productDisplay':
                res = await this.props.setMainProperties({store_id: this.store._id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1, targetDesign: "test"});
                console.log(res.data)
                break;
        }

        // let res = await this.props.shopNSkinInstall({});
        // console.log(res);
    };

    runSetting = async (type) => {
        let updateSettingData = {
            type : type,
            dataSet : {}
        }
        let res;
        switch (type) {
            case 'productDisplay':
                if(!this.getProductDisplayData[this.selSettingValue[type]]) {
                    alert("셋팅할 항목을 선택해 주세요.");
                    return false;
                }
                updateSettingData.dataSet = this.getProductDisplayData[this.selSettingValue[type]];
                console.log(updateSettingData.dataSet)
                //res = await this.props.designRunSetting(updateSettingData);
                break;
        }

        // let res = await this.props.shopNSkinInstall({});
        // console.log(res);
    };


    // onChangeInputs = async (event, targetObj) => {
    //     let { name, value } = event.target;
    //     if(targetObj) {
    //         targetObj[name] = value;
    //     }
    // };
    //
    //
    // shopNSkinInstall = async (item) => {
    //
    //     let res = await this.props.shopNSkinInstall({});
    //     console.log(res);
    // };


    render() {


        return (
            <>
                <div className="main-content">
                    <PageTitle />

                    <div onClick={e => this.shopNSkinInstall()}>업로드 테스트</div>
                    {( this.isLogin && this.teamUser.emailId ) ?
                        <div className="write-post-box">

                            {/* 기본 설정 */}
                            <div className="section-box" id="basicWriteSettings">
                                <div className="substitute-table">

                                    <div className="section-tit">
                                        <h3>멀티샵 / 디자인 선택</h3>
                                    </div>
                                    <table className="s-table">
                                        <colgroup>
                                            <col width="15%"/>
                                            <col width="35%"/>
                                            <col width="15%"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>멀티샵</th>
                                            <td>
                                                <FormSelect value={this.store.shopNo} name="shopNo"
                                                            onChange={event => {
                                                                this.onChangeShop(event, this.store)
                                                            }}
                                                >
                                                    {this.shops.map((item, i) => {
                                                        return (<option key={i} i={i} value={item.platformShopNo}>{item.shopName}</option>);
                                                    })}
                                                </FormSelect>
                                            </td>
                                            <th>셋팅할 디자인</th>
                                            <td>
                                                {/*<FormSelect value={this.store.shopNo} name="shopNo"*/}
                                                {/*            onChange={event => {*/}
                                                {/*                this.onChangeShop(event, this.store)*/}
                                                {/*            }}*/}
                                                {/*>*/}
                                                {/*    {this.shops.map((item, i) => {*/}
                                                {/*        return (<option key={i} i={i} value={item.platformShopNo}>{item.shopName}</option>);*/}
                                                {/*    })}*/}
                                                {/*</FormSelect>*/}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                    <div className="section-tit mt-3">
                                        <h3>설정처리</h3>
                                        {/*<p className={"text-warning"}>리뷰의 신뢰성을 유지하고 허위 리뷰로 오인되지 않도록 신중하게 설정해 주세요. 리뷰 노출 설정(이름 및 날짜 비공개 등)에 대한 책임은 전적으로 고객에게 있으며, 당사는 이에 대한 책임을 지지 않습니다.</p>*/}
                                    </div>
                                    <table className="s-table">
                                        <colgroup>
                                            <col width="20%"/>
                                            <col width="20%"/>
                                            <col width="20%"/>
                                            <col width="20%"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                        {/*<tr>*/}
                                        {/*    <th>상분분류관리</th>*/}
                                        {/*    <td>*/}
                                        {/*        <FormCheckbox toggle small checked={!this.addOnSetting.isLivePurchaseCount}*/}
                                        {/*                      onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLivePurchaseCount'])}>*/}
                                        {/*            {this.addOnSetting.isLivePurchaseCount ? '미노출' : '노출'}*/}
                                        {/*        </FormCheckbox>*/}
                                        {/*    </td>*/}
                                        {/*    <td><Button className="save-button" onClick={event => this.updateBasicSetting(event)}>적용</Button></td>*/}
                                        {/*    <td><Button className="save-button" onClick={event => this.updateBasicSetting(event)}>적용</Button></td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <th>상품정보표시</th>
                                            <td>
                                                <FormSelect value={this.selSettingValue.productDisplay} name="productDisplay"
                                                            onChange={event => {
                                                                this.onChangeSetType(event, this.selSettingValue, this.setProductDisplay, this.getProductDisplayData);
                                                            }}
                                                >
                                                    <option key={null} value={0}>---</option>
                                                    {Object.keys(this.getProductDisplayData).map((key, i) => {
                                                        return (<option key={key} i={i} value={key}>{key}</option>);
                                                    })}
                                                </FormSelect>
                                            </td>
                                            <td><Button className="save-button" onClick={event => this.getSetting("productDisplay")} >가져오기</Button></td>
                                            {/*<td><Button className="save-button" onClick={event => this.showDatas(event)}>데이터보기</Button></td>*/}

                                            <td colSpan={2}><Button className="save-button" onClick={event => this.setMainProperties("productDisplay")}>적용</Button></td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <th>상품등록</th>*/}
                                        {/*    <td colSpan={2}>*/}
                                        {/*        <FormCheckbox toggle small checked={!this.addOnSetting.isLivePurchaseCount}*/}
                                        {/*                      onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLivePurchaseCount'])}>*/}
                                        {/*            {this.addOnSetting.isLivePurchaseCount ? '미노출' : '노출'}*/}
                                        {/*        </FormCheckbox>*/}
                                        {/*    </td>*/}
                                        {/*    <td><Button className="save-button" onClick={event => this.updateBasicSetting(event)}>적용</Button></td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <th>메인상품진열</th>*/}
                                        {/*    <td colSpan={2}>*/}
                                        {/*        <FormCheckbox toggle small checked={!this.addOnSetting.isLivePurchaseCount}*/}
                                        {/*                      onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLivePurchaseCount'])}>*/}
                                        {/*            {this.addOnSetting.isLivePurchaseCount ? '미노출' : '노출'}*/}
                                        {/*        </FormCheckbox>*/}
                                        {/*    </td>*/}
                                        {/*    <td><Button className="save-button" onClick={event => this.updateBasicSetting(event)}>적용</Button></td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <th>리뷰</th>*/}
                                        {/*    <td colSpan={2}>*/}
                                        {/*        <FormCheckbox toggle small checked={!this.addOnSetting.isLivePurchaseCount}*/}
                                        {/*                      onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLivePurchaseCount'])}>*/}
                                        {/*            {this.addOnSetting.isLivePurchaseCount ? '미노출' : '노출'}*/}
                                        {/*        </FormCheckbox>*/}
                                        {/*    </td>*/}
                                        {/*    <td><Button className="save-button" onClick={event => this.updateBasicSetting(event)}>적용</Button></td>*/}
                                        {/*</tr>*/}


                                        </tbody>
                                    </table>
                                    {/*<div className="btn-save">*/}
                                    {/*    <Button className="save-button" onClick={event => this.updateBasicSetting(event)}>저장</Button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <ModalLoading isLoading={this.isLoading}/>
                        </div>
                        :
                        null
                    }
                </div>


            </>
        )
    }
}

export default DesignSetting;
