import React, {Component} from 'react';
import {decorate, observable, action, isObservableObject, toJS} from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import _ from "underscore";
import { srcToWebP } from 'webp-converter-browser'
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import ModalLoading from "../../../components/common/ModalLoading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeatherIcon from 'feather-icons-react';
import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Col, Row
} from "shards-react";
import moment from "moment";
import Calendar from "react-calendar";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";

let imgOptimizationType = 'webp';//webp, canvas
let imgOptimizationQuality = 0.8;//webp, canvas

@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, base64UploadImages: common.base64UploadImages, cafe24UploadFiles: common.cafe24UploadFiles, uploadImg: common.uploadImg, removeMedia: common.removeMedia, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ product }) => ({ getProductList: product.getProductList, setProductSync: product.setProductSync, updateProductReplay: product.updateProductReplay }))
@inject(({ review }) => ({ updateReview:review.updateReview, getAnalysisList:review.getAnalysisList, genAIReviewAnalysis:review.genAIReviewAnalysis }))

@observer
class ReviewWrite extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //list
  @observable listParams = {
    page:1, limit:20,
  }
  @observable searchParams = {
    keyword: '',
    overReviewCount: '',
  };
  @observable list = [];

  //modals
  @observable modals = {
    data: null,
    isWriteForm: false,
    widgetData: {},
    isMakeWidgetModal: false
  };

  @observable widgetUrl;

  @observable categories = [];

  @observable newReview = {};

  // @observable isShowCalendar = {
  //   createdAt: false
  // };

  @observable isShowCollapse = false;
  @observable isUploading = false;



  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.permissions = this.props.me.permissions;

      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }

      // if(this.store.presidentName !== "윤정진") {
      //   let isConfirm = window.confirm("해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.")
      //   if(!isConfirm) {
      //     window.history.back();
      //   }
      // }

      this.props.getDoc('getCategories', { store_id:this.teamUser.selStore_id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1 }, function(res) {
        _this.categories = res.data.categoriesData ? res.data.categoriesData : [];
        //this.categories;
      });
      // console.log(this.categories);

      this.initNewReview();
      this.listParams.store_id = this.teamUser.selStore_id;
      this.listParams.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo : 1;
      //this.listParams.teamUserId = this.teamUser.id;

      //url params
      for (const key of this.urlParams.keys()) {
        if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
          this.listParams[key] = parseInt(this.urlParams.get(key));
        }
        else {
          this.searchParams[key] = this.urlParams.get(key);
        }
      }

      this.getProductList();
    }

    if(this.teamUser.email === "admin@onnomads.com") {
      let _this = this;
      function generateRandomId(length) {
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      // 100개의 랜덤 ID 배열을 생성하는 함수
      function generateNamesArray(size, length) {
        const namesArray = [];
        for (let i = 0; i < size; i++) {
          namesArray.push(generateRandomId(length));
        }
        return namesArray;
      }

      // 특정 배열 정의
      var namesArray = generateNamesArray(100, 8);

      let lastClipboardContent = '';

      // 클립보드 내용을 주기적으로 확인하는 함수
      function checkClipboard() {
        navigator.clipboard.readText().then(text => {
          if (text && text !== lastClipboardContent) {
            lastClipboardContent = text;
            handleClipboardChange(text);
          }
        }).catch(err => {
          //console.error('Failed to read clipboard contents: ', err);
        });
      }


      // 클립보드 변경을 처리하는 함수
      function handleClipboardChange(text) {
        _this.newReview.contents = text;
        var randomName = namesArray[Math.floor(Math.random() * namesArray.length)];
        console.log(randomName)

        _this.newReview.writerName = randomName;
        // 추가 작업 처리
      }

      setInterval(checkClipboard, 2000);


    }

  };

  initNewReview = async () => {
    this.newReview = {
      store_id: this.teamUser.selStore_id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
      teamUser_id: this.teamUser.id,
      product_id: '',
      platform: this.store.platform,
      orderProductOptions: '',
      displayStatus: 2,
      goodsPt: 100,
      isSecret: false,
      isShow: true,
      isMobile: false,
      isDelete: false,
      images: [],
      movies: [],
      isGuest: false,
      isAdmin: true,
      isMember: true,
      writerName: '',
      writerPw: this.teamUser.emailId,
      subject: '',
      contents: '',
      fromWrite: '일반리뷰',
      createdAt: moment()._d,
      displayAt: moment()._d
    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.newReview[name] = value;
    }
    else {
      this.newReview[name] = value;
    }
  };

  onChangeCalendarValue = async (date, dateType) => {
    console.log(date)
    if(date) {
      this.newReview[dateType] = moment(date)._d;
    }
    else {
      this.newReview[dateType] = moment()._d;
    }

  };

  onAddDateTime = async (add, unit, dateType) => {
    let date = moment(this.newReview[dateType]).add(add, unit);
    this.newReview[dateType] = date._d;
  };


  // getMediaType = (file) => {
  //   let type;
  //   if(file.type.indexOf('image/') > -1) {
  //     type = 'image';
  //   }
  //   else if(file.type.indexOf('video/') > -1) {
  //     type = 'video';
  //   }
  //   return type;
  // };

  getMediaType = async (file) => {
    let type;

    if (file.type.startsWith('image/')) {
      if (file.type === 'image/gif') {
        const isAnimated = await this.isAnimatedGif(file);
        type = isAnimated ? 'animated-gif' : 'image';
      } else if (file.type === 'image/webp') {
        // WebP도 애니메이션인지 구분 가능하게 하려면 isAnimatedWebp 추가 가능
        type = 'webp';
      } else {
        type = 'image';
      }
    } else if (file.type.startsWith('video/')) {
      type = 'video';
    }

    return type;
  };

  isAnimatedGif = async (file) => {
    const buffer = await file.arrayBuffer();
    const byteArray = new Uint8Array(buffer);

    let frames = 0;

    for (let i = 0; i < byteArray.length - 9; i++) {
      // Graphic Control Extension: 0x21, 0xF9
      if (
          byteArray[i] === 0x21 &&
          byteArray[i + 1] === 0xF9 &&
          byteArray[i + 2] === 0x04
      ) {
        frames++;
        if (frames > 1) {
          return true; // 두 개 이상의 프레임 => 애니메이션
        }
      }
    }

    return false;
  };


  convertWebp = async (imgSrc, cb) => {
    let imgToWebpBlob = await srcToWebP(imgSrc, { quality: imgOptimizationQuality*100 });
    // let blobTowebp = await webpConverterBrowser.blobToWebP(imgToWebpBlob, { quality: 100 });
    // console.log(blobTowebp);
    // let bufferToWebpBlob = await webpConverterBrowser.arrayBufferToWebP('./img/goods/sample01.jpg', { quality: 100 });
    let webpBlobSrc = URL.createObjectURL(imgToWebpBlob);
    let tempFile = new File([imgToWebpBlob], 'image_file.webp', { type: imgToWebpBlob.type });
    cb(webpBlobSrc, tempFile);
  };

  onFileUpload = async (event) => {
    let _this = this;

    let file = event.target.files[0];
    if (file) {
      const allowedExtensions = ["image/", "video/mp4", "video/quicktime"];
      const fileType = file.type;

      if (!allowedExtensions.some(ext => fileType.startsWith(ext))) {
        alert("이미지 파일 또는 MP4, MOV 형식의 영상만 선택 가능합니다.");
        event.target.value = ""; // 선택된 파일 초기화
      }
    }

    _this.isUploading = true;
    let mediaType = await _this.getMediaType(file);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxWidth = 2048; //너비 또는 높이가 0보다 작으면 이미지 사이즈 조절 처리 안함.
    const maxHeight = 2048;
    const maxFileSize = 1024 * 1024; // 1MB
    let compressedDataUrl;

    const reader = new FileReader();
    reader.onload = async function(event) {
      let orgFileName = file.name.split(".")[0];
      if(mediaType === 'animated-gif' || mediaType === 'webp') {
        const formData = new FormData();
        formData.append("file", file);
        orgFileName = file.name;

        await fetch(process.env.NODE_ENV === "development" ? 'https://orpapi.onreview.co.kr/api/upload' : 'https://orpapi.onreview.co.kr/api/upload' , {
          method: 'POST',
          body: formData
        })
            .then(response => response.json())
            .then(data => {
              let toUploadParams = {
                store_id: _this.store._id,
                imagesUrls: [data[0].fullPath] // 경로 변환 및 null 체크
              };

              _this.props.cafe24UploadFiles(toUploadParams, async function(res) {
                console.log(res)
                _this.newReview.images.push({name: orgFileName, url:res.files[0].url});
                _this.isUploading = false;
              });
            })
            .catch(error => console.error('Error:', error));

        _this.isUploading = false;
      }
      else if (mediaType === 'image') {
        if(file.size / 1000000 > 60) {
          alert('파일 용량이 60MB 이하만 등록이 가능합니다.');
          return false;
        }
        const imgObj = new Image();
        imgObj.onload = async function() {
          let width = imgObj.width;
          let height = imgObj.height;
          if (maxWidth > 0 && maxHeight > 0) {
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }
          }

          if(imgOptimizationType === 'webp') {
            _this.convertWebp(imgObj.src, async function(compressedDataUrl, tempFile) {
              const formData = new FormData();
              formData.append("file", tempFile);

              // const formData = new FormData();
              // formData.append("file", file);
              await fetch(process.env.NODE_ENV === "development" ? 'https://orpapi.onreview.co.kr/api/upload' : 'https://orpapi.onreview.co.kr/api/upload', {
                method: 'POST',
                body: formData
              })
                  .then(response => response.json())
                  .then(data => {
                    let toUploadParams = {
                      store_id: _this.store._id,
                      imagesUrls: [data[0].fullPath] // 경로 변환 및 null 체크
                    };

                    _this.props.cafe24UploadFiles(toUploadParams, async function(res) {
                      console.log(res)
                      _this.newReview.images.push({name: orgFileName+".webp", url:res.files[0].url});
                      _this.isUploading = false;
                    });
                  })
                  .catch(error => console.error('Error:', error));

              _this.isUploading = false;

            });
          }
          else if(imgOptimizationType === 'canvas') {

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(imgObj, 0, 0, width, height);
            compressedDataUrl = canvas.toDataURL('image/jpeg', imgOptimizationQuality);

            _this.props.base64UploadImages({store_id: _this.teamUser.selStore_id, base64Imgs:[{image:compressedDataUrl}]}, function(res) {
              _this.newReview.images.push({url:res.images[0].path,  name: file.name});
              _this.isUploading = false;
            });
          }
          else {

            const formData = new FormData();
            formData.append("file", file);

            // const formData = new FormData();
            // formData.append("file", file);
            await fetch(process.env.NODE_ENV === "development" ? 'https://orpapi.onreview.co.kr/api/upload' : 'https://orpapi.onreview.co.kr/api/upload', {
              method: 'POST',
              body: formData
            })
                .then(response => response.json())
                .then(data => {
                  let toUploadParams = {
                    store_id: _this.store._id,
                    imagesUrls: [data[0].fullPath] // 경로 변환 및 null 체크
                  };

                  _this.props.cafe24UploadFiles(toUploadParams, async function(res) {
                    console.log(res)
                    _this.newReview.images.push({name: orgFileName+".webp", url:res.files[0].url});
                    _this.isUploading = false;
                  });
                })
                .catch(error => console.error('Error:', error));

            _this.isUploading = false;

          }
        }
        imgObj.src = event.target.result;

      } else if (mediaType === 'video') {

        const ffmpeg = createFFmpeg({ log: true });

        if (!ffmpeg.isLoaded()) {
          await ffmpeg.load();
        }

        const fileName = "input_video." + file.name.split(".").pop();
        const outputFileName = "output.webp";

        ffmpeg.FS("writeFile", fileName, await fetchFile(file));

        // FFmpeg를 사용한 WebP 변환 (10fps, 480px 너비)
        await ffmpeg.run(
            "-i", fileName,
            "-vf", "fps=10,scale=480:-1",
            "-loop", "0",
            outputFileName
        );

        const data = ffmpeg.FS("readFile", outputFileName);
        const webpBlob = new Blob([data.buffer], { type: "image/webp" });
        const webpFile = new File([webpBlob], orgFileName + ".webp", { type: "image/webp" });
        // const webpUrl = URL.createObjectURL(webpBlob);

        // WebP 업로드
        const formData = new FormData();
        formData.append("file", webpFile);

        await fetch(process.env.NODE_ENV === "development" ? 'https://orpapi.onreview.co.kr/api/upload' : 'https://orpapi.onreview.co.kr/api/upload', {
          method: 'POST',
          body: formData
        })
            .then(response => response.json())
            .then(async data => {
              let toUploadParams = {
                store_id: _this.store._id,
                imagesUrls: [data[0].fullPath] // 경로 변환 및 null 체크
              };

              _this.props.cafe24UploadFiles(toUploadParams, async function(res) {
                console.log(res)
                _this.newReview.images.push({name: orgFileName+".webp", url:res.files[0].url});
                _this.isUploading = false;
              });

            })
            .catch(error => {
              console.error('WebP 업로드 실패:', error);
              _this.isUploading = false;
            });

      }
    }
    reader.readAsDataURL(file);
  }

  onClickMediaRemove = async (item, i, mediaType) => {
    let params =  {
      mediaType: mediaType,
      image_id: item.cloudflare_id
    }
    this.newReview[mediaType].splice(i, 1);
    let res = await this.props.removeMedia(params);
  };

  updateReview = async (product) => {

    if(!this.isUse) {
      alert('유료회원 전용 기능입니다.');
      return false;
    }

    let _this = this;
    _this.newReview.product_id = product._id;
    _this.newReview.shopNo = _this.teamUser.selShopNo ? _this.teamUser.selShopNo : 1;
    _this.newReview.platformProductId = product.platformProductId;
    _this.newReview.orderProductName = product.productName;
    if(!_this.newReview.writerName || _this.newReview.writerName == '') {
      alert('작성자를 입력해 주세요.');
      return false;
    }
    else if(!_this.newReview.contents || _this.newReview.contents == '') {
      alert('리뷰 내용을 입력해 주세요.');
      return false;
    }
    _this.newReview.goodsPt = parseInt(_this.newReview.goodsPt);

    this.props.updateReview(_this.newReview, function(res) {
      _this.modals.isWriteForm = false;
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        if(res.data.message) {
          if(res.data.message.indexOf("maximum size of") > -1) {
            toast("카페24의 리뷰 게시판의 파일첨부기능 및 첨부파일용량제한을 확인해 주세요.");
          }
          else {
            toast("리뷰가 정상적으로 작성이 되지 않았습니다.");
          }

        }
        else {
          toast("리뷰가 작성되었습니다.")
        }

        // _this.newReplyContents = {
        //   grade: null,
        //   text: ''
        // };
      }
      _this.initNewReview();

    });
  }

  genAIReviewAnalysis = async (data) => {
    console.log(data)
    let params = {
      store_id: data.product.store_id,
      product_id: data.product._id
    }
    this.props.genAIReviewAnalysis(params, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        console.log(res);

      }
    });
  };

  // setDropdown = async (target, value) => {
  //   if(target == 'isCreatedAt') {
  //     if(value == 'tomorrow') {
  //       this.newReview.createdAt = moment().add(1, 'days').format('yyyy-MM-DD');
  //     }
  //     else if(value == 'input') {
  //       this.isShowCalendar.createdAt = true;
  //     }
  //     else {
  //       this.newReview.createdAt = moment().format('yyyy-MM-DD');
  //     }
  //   }
  // };

  getProductList = async() => {
    if(this.listParams.store_id) {
      this.listParams.searchParams = this.searchParams;
      this.list = await this.props.getProductList(this.listParams);
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };

  search = async() => {
    let url = new URL(window.location.href);
    let urlParams = new URLSearchParams();
    for(let key in this.searchParams) {
      if(key != 'store_id' && key != 'teamUserId' && key != 'limit' && this.searchParams[key])
        urlParams.set(key, this.searchParams[key]);
    }
    window.location.search = urlParams.toString();
  };

  onChangeSearchValue = async (event) => {
    const { name, value, type } = event.target;
    if(type == 'checkbox') {
      if(value) {
        this.searchParams[name] = !JSON.parse(value);
      }
      else {
        this.searchParams[name] = true;
      }
    }
    else {
      this.searchParams[name] = value;
    }
  };




  // setProductSync = async (event) => {
  //   let _this = this;
  //   if(! await this.checkPaidUser()) return false;
  //   _this.isLoading = true;
  //   let data = {
  //     store_id: _this.teamUser.selStore_id,
  //     shopNo: _this.teamUser.selShopNo,
  //     teamUser_id: _this.teamUser.id,
  //     // limit: 5,
  //     // page: 1
  //   }
  //
  //   _this.props.setProductSync(data, function(res) {
  //     if(res.errors && res.errors.length > 0) {
  //       toast(res.errors[0].message);
  //     }
  //     else {
  //       setTimeout(() => {
  //         toast(res.data.productCount + "상품정보 업데이트 완료 되었습니다.");
  //         _this.getProductList();
  //       }, 3000);
  //     }
  //
  //   });
  // };

  updateProductReplay = async (item) => {
    let _this = this;
    console.log(item)
    let params = {
      store_id: this.store._id,
      product_id: item._id,
    }
    if(item.vr_productReply._id) {
      params.id = item.vr_productReply._id;
    }
    if(item.vr_productReply.type) {
      params.type = item.vr_productReply.type;
    }
    if(item.vr_productReply.tone) {
      params.tone = item.vr_productReply.tone;
    }
    console.log(params)


    _this.props.updateProductReplay(params, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast("업데이트 완료 되었습니다.")
      }
    });
  }

  modalToggle = function(target, data) {
    let _this = this;
    if(!target) {
      for( var i in this.modals) {
        if(i.indexOf('is') === 0) {
          this.modals[i] = false;
        }
      }
    }
    else {
      this.modals[target] = !this.modals[target];
    }
    if(data) {
      if(target === 'isMakeWidgetModal' && data) {
        this.modals.widgetData.product = data;
        let params = {
          store_id: data.store_id,
          product_id: data._id,
        }
        this.props.getAnalysisList(params, function(res) {
          if(res.errors && res.errors.length > 0) {
            toast(res.errors[0].message);
          }
          else {
            _this.modals.widgetData.aiList = res;
            _this.showPreviewWidget(_this.modals.widgetData.aiList.docs[0]);
          }
        });

      }
      else {
        this.modals.data = data;
      }

    }
  };

  showPreviewWidget = function(data) {
    console.log(data);
    this.widgetUrl = "https://onreple-skin-widget.pages.dev/widget/ai/basic.html?s_id=" + data.store_id + "&a_id=" + data._id+ "&p_id=" + data.product_id;
  }

  checkPaidUser = async () => {
    if(!this.teamUser.isPaidShop) {
      alert("유료 사용자만 이용이 가능합니다.");

    }
    return this.teamUser.isPaidShop;
  }

  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      let limit = _this.list.limit;
      let totalPages = _this.list.totalPages;
      let curPageNum = _this.list.page;
      let nextPage = _this.list.nextPage;
      let prevPage = _this.list.prevPage;
      let startNum = (parseInt((curPageNum-1)/10)*10) +1;
      let endNum = startNum+9 < totalPages ? startNum+9 : totalPages;

      let urlParamsArr = new URLSearchParams(window.location.search);
      urlParamsArr.delete('page');
      let urlParams = '?' + urlParamsArr.toString();

      let rendering = () => {
        for(let i = startNum; i <= endNum; i ++) {
          if(i == curPageNum) {
            result.push(<li key={i} className="on"><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
          else {
            result.push(<li key={i}><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
        }
        return result;
      }

      if(_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                { curPageNum != 1 ? <li className="btn-page-first"><a href={urlParams + "&page=1"}><FeatherIcon icon="chevrons-left" className="nd-icon" /></a></li> : null}
                { startNum - 1 > 0 ? <li className="btn-page-prev"><a href={urlParams + "&page="+(startNum-1)}><FeatherIcon icon="chevron-left" className="nd-icon" /></a></li> : null}
                {rendering()}
                { endNum != totalPages  ? <li className="btn-page-next"><a href={urlParams + "&page="+(startNum+10)}><FeatherIcon icon="chevron-right" className="nd-icon" /></a></li> : null}
                { curPageNum != totalPages ? <li className="btn-page-last"><a href={urlParams + "&page="+totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon" /></a></li> : null}
              </ul>
            </div>);
      }
      else {
        return (<></>);
      }

    }

    function AvgScore(props) {
      let avg = 0;
      let scoreInfo = props.scoreInfo;
      if(scoreInfo.reviewCount > 0) {
        avg = parseFloat((scoreInfo.goodsPtSum / scoreInfo.reviewCount / 20).toFixed(2));
      }
      else {
        avg = 0;
      }
      return avg;
    }

    return (
        <div className="main-content">
          <PageTitle />
          <p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>
          {/*<Row className="mtb-10 text-right">*/}
          {/*  <Col sm={{ size: 2, order: 2, offset: 10 }} >*/}
          {/*    <Button size="sm" onClick={event=> this.setProductSync()}><FeatherIcon size="17" icon="download" className="nd-icon" />&nbsp;&nbsp;상품정보 가져오기</Button>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <div className="review-write-box">
            <div className="section-box">
              <div className="product-search-box">
                <div className="search-input-box">
                  <FormSelect name="category" onChange={event => {
                    this.onChangeSearchValue(event)
                  }} value={this.searchParams.category} className="search-type">
                    <option value="">-카테고리 전체-</option>
                    {this.categories ?
                        this.categories.map((item, i) => (
                            <option key={i} value={item.category_no}>{item.category_name}</option>
                        ))
                        : null
                    }
                  </FormSelect>
                  <FormInput name="keyword" placeholder="상품명 검색" onChange={event => {this.onChangeSearchValue(event)}}
                             onKeyDown={event => { if(event.keyCode === 13) this.search()}}
                             value={this.searchParams.keyword} className="table-input-text search-input"/>
                </div>
                {/*<div className="check-exhibition">*/}
                {/*  <label>*/}
                {/*    <input type="checkbox" />*/}
                {/*      <span>현재 진열 상태인 상품만 보기</span>*/}
                {/*  </label>*/}
                {/*</div>*/}
                <div className="search-input-box mt-2">
                  <FormSelect name="overReviewCount" onChange={event => {
                    this.onChangeSearchValue(event)
                  }} value={this.searchParams.overReviewCount} className="search-type">
                    <option value="">-리뷰 수 n개 이상 상품-</option>
                    <option value="1">리뷰 수 1개이상</option>
                    <option value="5">리뷰 수 5개이상</option>
                    <option value="10">리뷰 수 10개이상</option>
                    <option value="30">리뷰 수 30개이상</option>
                    <option value="50">리뷰 수 50개이상</option>
                    <option value="100">리뷰 수 100개이상</option>
                    <option value="500">리뷰 수 500개이상</option>
                    <option value="1000">리뷰 수 1000개이상</option>
                  </FormSelect>
                </div>
              </div>
              <div className="btn-search">
                <Button onClick={event => this.search()}>검색</Button>
              </div>
            </div>


            <div className="section-box review-write-box">

              <div className="product-list-box">
                <div className="product-pick-list">
                  <div className="pick-list-count">
                    총 <span>{this.list && this.list.totalDocs ? this.list.totalDocs : 0}</span>개
                  </div>
                </div>
                <table>
                  <colgroup>
                    {/*<col width="3%"/>*/}
                    <col width="88px"/>
                    <col />
                    {/*<col width="120px"/>*/}
                    {/*<col width=""/>*/}
                    <col width="20%"/>
                    <col width="20%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    {/*<th>상품코드</th>*/}
                    <th>이미지</th>
                    <th>상품 이름</th>
                    {/*<th>답글 유형</th>*/}
                    {/*<th>답글 추가 내용</th>*/}
                    <th>리뷰수</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {/*짱구판 쇼미더머니라는 별칭이 있는 짱구 랩배틀 시리즈 유리의 랩배틀 실력을 인정한 그 장면 기억 하시나요? 너무 귀엽네요*/}
                  {this.list && this.list.docs && this.list.docs.length > 0 ?
                      this.list.docs.map((item, i) => (
                          <tr key={i}>
                            {/*<td className="product-code">{item.platformProductId}</td>*/}
                            <td>
                              <div className="product-thumb"><img src={item.images.s_1} /></div>
                            </td>
                            <td className="left">
                              <div className="product-title"><a
                                  href={'//' + (this.teamUser.shop.primaryDomain ? this.teamUser.shop.primaryDomain : this.store.platformId + '.cafe24.com') + '/product/' + item.productName + '/' + item.platformProductId}
                                  target='_blank'>{item.productName}</a>
                              </div>
                              <div  className="link-naverstore-search"><a
                                href={"https://search.shopping.naver.com/search/all?query=" + encodeURI(item.productName)}
                                target='_blank'>네이버 스토어 검색</a>
                              </div>
                            </td>
                            {/* // todo 별점과 해당 속성이 중복이 되어 일시적으로 사용 안함 */}
                            {/*<td>*/}
                            {/*  <FormGroup>*/}
                            {/*    <FormSelect name="type"*/}
                            {/*        value={item.vr_productReply ? item.vr_productReply.type : ''}*/}
                            {/*        disabled={!item.vr_productReply || !item.vr_productReply.tone || item.vr_productReply.tone === ''}*/}
                            {/*        onChange={event => {*/}
                            {/*          item.vr_productReply ? item.vr_productReply.type = event.target.value : item.vr_productReply = {type: event.target.value};*/}
                            {/*          this.updateProductReplay(item);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*      <option value="na">추가안함</option>*/}
                            {/*      <option value="ai">ai</option>*/}
                            {/*      <option value="fixed">고정</option>*/}
                            {/*    </FormSelect>*/}
                            {/*  </FormGroup>*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <InputGroup>*/}
                            {/*    <FormTextarea name="tone"*/}
                            {/*                  value={item.vr_productReply ? item.vr_productReply.tone : ''}*/}
                            {/*                  onChange={event => {*/}
                            {/*                    item.vr_productReply ? item.vr_productReply.tone = event.target.value : item.vr_productReply = {tone: event.target.value};*/}
                            {/*                  }}*/}
                            {/*                  placeholder={'AI 또는 고정 답글에 추가할 상품 내용을 입력해 주세요.'}*/}
                            {/*    />*/}
                            {/*    <InputGroupAddon type="append">*/}
                            {/*      <Button theme="info" onClick={e => this.updateProductReplay(item)}>저장</Button>*/}
                            {/*    </InputGroupAddon>*/}

                            {/*  </InputGroup>*/}
                            {/*</td>*/}
                            {item.scoreInfo ?
                                <td className="review-count left">
                                <div className="review-normal">
                                    일반리뷰 : {item.scoreInfo.reviewCount} / 평점 : <AvgScore scoreInfo={item.scoreInfo} />
                                  </div>
                                  {/*<div className="review-sns">*/}
                                  {/*  SNS리뷰 12,567*/}
                                  {/*</div>*/}
                                </td>
                                : <td>-</td>
                            }
                            <td>
                              {/*<button className="btn-review-write"*/}
                              {/*        onClick={e => this.modalToggle('isWriteForm', item)}>리뷰 가져오기*/}
                              {/*</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                              <Button className="btn-review-write"
                                      disabled={!this.isUse}
                                      theme="success"
                                      onClick={e => this.modalToggle('isWriteForm', item)}>리뷰 작성
                              </Button>
                            </td>
                          </tr>
                      ))
                      :
                      <tr>
                        <td></td>
                      </tr>
                  }
                  </tbody>
                </table>
                {/*<div className="btn-area">*/}
                {/*  <button className="btn-file-upload">파일 업로드</button>*/}
                {/*</div>*/}
                <Pagination />
              </div>
            </div>
          </div>

          <Modal size="lg" className='modal-review-write-form' open={this.modals.isWriteForm} toggle={this.modalToggle.bind(this)} centered={true}>
            <ModalHeader>리뷰 작성</ModalHeader>
            <ModalBody className="history-window-popup">
              <p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>
              <div className="history-popup-contents">
                <div className="popup-contents-main">
                  <h4>상품정보</h4>
                  {this.modals.data ? (
                      <table>
                        <colgroup>
                          <col style={{width: '15%'}}/>
                          <col/>
                          <col style={{width: '15%'}}/>
                          <col style={{width: '15%'}}/>
                        </colgroup>
                        <tbody>
                        <tr>
                          <td><img src={this.modals.data.images.s_1}/></td>
                          <td>{this.modals.data.productName}</td>
                          <td>리뷰수 : {this.modals.data.scoreInfo ? this.modals.data.scoreInfo.reviewCount : 0}</td>
                          <td>평점 : <AvgScore scoreInfo={this.modals.data.scoreInfo}/></td>
                        </tr>
                        </tbody>
                      </table>) : null}
                  <h4>리뷰 내용</h4>
                  <FormGroup>
                    <InputGroup>
                      <DateTimePicker value={this.newReview.displayAt} onChange={date => {this.onChangeCalendarValue(date, 'displayAt' )}} />
                      <ButtonGroup size="sm" className="ml-2">
                        <Button outline onClick={date => {this.onAddDateTime(5, 'm', 'displayAt')}} theme="secondary">+5분</Button>
                        <Button outline onClick={date => {this.onAddDateTime(30, 'm', 'displayAt')}} theme="secondary">+30분</Button>
                        <Button outline onClick={date => {this.onAddDateTime(2, 'h', 'displayAt')}} theme="secondary">+2시간</Button>
                        <Button outline onClick={date => {this.onAddDateTime(6, 'h', 'displayAt')}} theme="secondary">+6시간</Button>
                        <Button outline onClick={date => {this.onAddDateTime(1, 'd', 'displayAt')}} theme="secondary">+1일</Button>
                      </ButtonGroup>
                      {/*<FormInput name="writerName" placeholder="작성자" value={this.newReview.writerName} onChange={event => {this.onChangeValue(event)}} className="ml-2 border-radius" />*/}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <FormInput name="writerName" placeholder="작성자" value={this.newReview.writerName}
                               onChange={event => {
                                 this.onChangeValue(event)
                               }} className="border-radius"/>
                  </FormGroup>
                  <FormGroup>
                    <FormTextarea name="contents" placeholder="리뷰내용을 작성해 주세요." value={this.newReview.contents}
                                  onChange={event => {
                                    this.newReview.contents = this.newReview.contents ? this.newReview.contents : {};
                                    this.onChangeValue(event)
                                  }}/>
                  </FormGroup>

                  <h4>상품 별점</h4>
                  <FormGroup>
                    <FormSelect name="goodsPt" value={this.newReview.goodsPt} onChange={event => {
                      this.newReview.goodsPt = this.newReview.goodsPt ? this.newReview.goodsPt : {};
                      this.onChangeValue(event)
                    }}>
                      <option value="100">★★★★★</option>
                      <option value="80">★★★★</option>
                      <option value="60">★★★</option>
                      <option value="40">★★</option>
                      <option value="20">★</option>
                    </FormSelect>
                  </FormGroup>
                  <h4>배송 별점</h4>
                  <FormGroup>
                    <FormSelect name="goodsPt" value={this.newReview.deliveryPt} onChange={event => {
                      this.newReview.deliveryPt = this.newReview.deliveryPt ? this.newReview.deliveryPt : {};
                      this.onChangeValue(event)
                    }}>
                      <option value="100">★★★★★</option>
                      <option value="80">★★★★</option>
                      <option value="60">★★★</option>
                      <option value="40">★★</option>
                      <option value="20">★</option>
                    </FormSelect>
                  </FormGroup>
                  <div>
                    <input type="file" id="upload" name="file" accept="image/*,video/mp4,video/quicktime" onChange={event => {
                      this.onFileUpload(event)
                    }}/>
                    <p className="text-info">MP4, MOV 형식의 영상 파일은 업로드 시 자동으로 움직이는 이미지(WEBP)로 변환됩니다.</p>
                    <p className="text-info">※ 해상도, 부분영상 등 <strong>세부 옵션 조정이 필요한 경우</strong>에는 아래의 외부 툴을 이용하시는 것을 권장합니다.</p>
                    <p className="text-info mtb-10">
                      <a href="https://mov2gif.com/index_kr" target="_blank">mov2gif.com 바로가기</a>
                    </p>
                    <div className="p-3 mt-3 border rounded media-uploaded-box">
                      <Collapse
                          open={this.newReview.images && (this.newReview.images.length > 0 || this.newReview.movies.length > 0)}>
                        <ul>
                          {this.newReview && this.newReview.movies && this.newReview.movies.length > 0 ?
                              this.newReview.movies.map((item, i) => (<li key={i}>
                                {typeof item === 'object' ?
                                    <img
                                        src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item.src + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"}/>
                                    :
                                    <img
                                        src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"}/>
                                }
                                <span className="btn-media-remove"
                                      onClick={event => this.onClickMediaRemove(item, i, 'movies')}>X</span>
                              </li>)) : null}
                          {this.newReview && this.newReview.images && this.newReview.images.length > 0 ?
                              this.newReview.images.map((item, i) => (<li key={i}>
                                <img src={item.url}/>
                                <span className="btn-media-remove"
                                      onClick={event => this.onClickMediaRemove(item, i, 'images')}> X</span>
                              </li>)) : null}
                        </ul>
                      </Collapse>
                    </div>
                  </div>
                </div>


                <div className="text-center">
                  {!this.isUploading ? <Button onClick={event => this.updateReview(this.modals.data)} >리뷰작성</Button> : null}
                  <Button className="ml-2" theme="light" onClick={event=> this.modals.isWriteForm = false} >닫기</Button>
                </div>
              </div>

            </ModalBody>
          </Modal>
          <ModalLoading isLoading={this.isLoading} />
        </div>
    )
  }
}

export default ReviewWrite;
