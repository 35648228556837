import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import _ from "underscore";
import { srcToWebP } from 'webp-converter-browser'
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import ModalLoading from "../../../components/common/ModalLoading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeatherIcon from 'feather-icons-react';
import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import moment from "moment";
import Calendar from "react-calendar";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";

let imgOptimizationType = '';//webp, canvas
let imgOptimizationQuality = 0.8;//webp, canvas

@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, uploadImg: common.uploadImg, removeMedia: common.removeMedia, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ product }) => ({ getProductList: product.getProductList, setProductSync: product.setProductSync }))
@inject(({ review }) => ({ updateReview:review.updateReview, getAnalysisList:review.getAnalysisList, getAnalysisWidgetList:review.getAnalysisWidgetList, genAIReviewAnalysis:review.genAIReviewAnalysis, genAITotal:review.genAITotal,
  selectAnalysisProductReview:review.selectAnalysisProductReview, updateAnalysisProductReview:review.updateAnalysisProductReview, removeAnalysisProductReview: review.removeAnalysisProductReview }))

@observer
class totalAiWidget extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;


  //page
  @observable categories;
  @observable ai = {
    categoryNo: ""
  };


  constructor(props) {
    super(props);
    this.init()
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }
      this.permissions = this.props.me.permissions;

      this.props.getDoc('getCategories', { store_id:this.teamUser.selStore_id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1 }, function(res) {
        _this.categories = res.data.categoriesData ? res.data.categoriesData : [];
        _this.ai.categoryNo = _this.categories[0].category_no;
      });
    }

  };


  onChangCategory = async (event) => {
    const { name, value } = event.target;

    this.ai.categoryNo = value;
  };



  getTotalAi = async (data) => {
    let params = {
      store_id: this.store._id,
      shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1,
      categoryNo: parseInt(this.ai.categoryNo)
    }
    this.props.genAITotal(params, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        console.log(res);

      }
    });
  };




  render() {
    let _this = this;


    return (
        <div className="main-content">
          <PageTitle />

          <div className="review-widget-box">
            <div className="section-box">
              <div className="search-input-box float-left">
                <FormSelect name="category" onChange={event => {
                  this.onChangCategory(event)
                }} value={this.ai.categoryNo} className="search-type">
                  {this.categories ?
                      this.categories.map((item, i) => (
                          <option key={i} value={item.category_no}>{item.category_name}</option>
                      ))
                      : null
                  }
                </FormSelect>
              </div>
              <div className="float-right">
                <Button disabled={!this.isUse} theme="success" onClick={e => this.getTotalAi()}>AI 분석하기</Button>
              </div>
            </div>
            <div className="section-box">
              <div className="no-data">
                <p>해당 카테고리의 분석 데이터가 없습니다.</p>
                <p className="mt-5"><Button size="lg" theme="success" onClick={e => this.getTotalAi()}>AI 분석하기</Button></p>
                </div>
            </div>
            <div>

              <div className="row">
                <div className="col-lg col-md-6 col-sm-6 mb-4">
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">누적 분석리뷰수</span>
                          <h6 className="stats-small__value count my-3">2,390</h6>
                        </div>
                        <div className="stats-small__data">
                          <span className="stats-small__percentage stats-small__percentage--increase">4.7%</span>
                        </div>
                      </div>
                      <canvas height="120" className="blog-overview-stats-small-1"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-md-6 col-sm-6 mb-4">
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">긍정평가</span>
                          <h6 className="stats-small__value count my-3">182</h6>
                        </div>
                        <div className="stats-small__data">
                          <span className="stats-small__percentage stats-small__percentage--increase">12.4%</span>
                        </div>
                      </div>
                      <canvas height="120" className="blog-overview-stats-small-2"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-md-4 col-sm-6 mb-4">
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">부정평가</span>
                          <h6 className="stats-small__value count my-3">8,147</h6>
                        </div>
                        <div className="stats-small__data">
                          <span className="stats-small__percentage stats-small__percentage--decrease">3.8%</span>
                        </div>
                      </div>
                      <canvas height="120" className="blog-overview-stats-small-3"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-md-4 col-sm-6 mb-4">
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">이번달 주문수</span>
                          <h6 className="stats-small__value count my-3">2,413</h6>
                        </div>
                        <div className="stats-small__data">
                          <span className="stats-small__percentage stats-small__percentage--increase">12.4%</span>
                        </div>
                      </div>
                      <canvas height="120" className="blog-overview-stats-small-4"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg col-md-4 col-sm-12 mb-4">
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">총리뷰수</span>
                          <h6 className="stats-small__value count my-3">17,281</h6>
                        </div>
                        <div className="stats-small__data">
                          <span className="stats-small__percentage stats-small__percentage--decrease">2.4%</span>
                        </div>
                      </div>
                      <canvas height="120" className="blog-overview-stats-small-5"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-lg-6 col-md-6 col-sm-12 mb-6">
                  <div className="card card-small h-100">
                    <div className="card-header border-bottom">
                      <h6 className="m-0">Users by device</h6>
                    </div>
                    <div className="card-body d-flex py-0">
                      <canvas height="220" className="blog-users-by-device m-auto"></canvas>
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
    )
  }
}

export default totalAiWidget;
