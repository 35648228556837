import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "underscore";
import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    FormSelect,
    FormInput,
    Button,
    FormCheckbox,
    FormTextarea,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormRadio
} from "shards-react";
//https://designrevision.com/docs/shards-react/getting-started
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ChromePicker} from "react-color";
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import moment from "moment";
import ModalLoading from "../../../components/common/ModalLoading";
import {Design} from "../../../stores/Design";

let initSetting = {
    writer: '',
    password: '',
    isAutoWrite: false,
    isShowWriterName:  false,
    isShowProductName: false,
    sentencesNum: '2~3',
    autoReplyStartTime: 9,
    autoReplyEndTime: 18,
    autoReplyFrequency: 3,
    autoRunTimes: [],
    atmosphere: ['', '', ''],
    reviewRatingConfigs: [
        { isAutoWrite: true, rating: '1_20', type: 'na', isShowWriterName:false, isShowProductName: false, tone: ''},
        { isAutoWrite: true, rating: '21_40', type: 'fixed', isShowWriterName:false, isShowProductName: false, tone: '안녕하세요. [구매자명]님. [상품명]리뷰 작성 감사합니다. \n' +
                '[상품별 추가 내용]' +
                '고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
        { isAutoWrite: true, rating: '41_100', type: 'ai', isShowWriterName:true, isShowProductName: true, tone: '안녕하세요. 고객님. 리뷰 작성 감사합니다. 고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
    ],
    defaultColor: "#000000",
    themeColor: "#121212",
    subColor: "#6255F6",
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function isOverThreeMonths(date1, date2) {
    const startDate = moment(date1);
    const endDate = moment(date2);
    const monthsDiff = endDate.diff(startDate, 'months');
    return monthsDiff < 3;
}

function isOverPeriod(date1, date2, periodArr) {
    const startDate = moment(date1);
    const endDate = moment(date2);
    const periodDiff = endDate.diff(startDate, periodArr[1]);
    return periodDiff < periodArr[0];
}

@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, getMe:teamUser.getMe }))
@inject(({ company }) => ({ updateStore:company.updateStore, getApplySkins:company.getApplySkins, getApplyShop:company.getApplyShop, applyAllSkin:company.applyAllSkin, applySkin:company.applySkin}))
@inject(({ design }) => ({ shopNSkinInstall: design.shopNSkinInstall }))

@observer
class DesignAddOn extends Component {

    //basic
    @observable teamUser = {};
    @observable permissions = {}
    @observable store = {};
    @observable company = {};
    @observable isLogin = false;
    @observable isLoading = false;

    //page

    @observable store = {};
    @observable shops = [];
    @observable addOnSetting = {};


    constructor(props) {
        super(props);
        this.init()
    }


    @action
    init = async (key) => {
        let _this = this;
        if(this.props.isLogin) {
            //basic
            this.isLogin = this.props.isLogin;
            this.teamUser = this.props.me;
            this.store = this.teamUser.store;
            this.permissions = this.props.me.permissions;
            this.getApplyShop(false);
        }
        // 멀티샵의 스킨 활성화 - 해당 쇼핑몰의 파일 업로드 -> 업로드 파일 링크 연결
        //

    };

    getApplyShop = async (actSync) => {
        let _this = this;
        let updateStore = {
            store_id: this.teamUser.store._id
        }
        this.props.getApplyShop( updateStore, function(res) {
            if(res.errors && res.errors.length > 0) {
                toast(res.errors[0].message);
            }
            else {
                _this.shops = res.data;
                if(actSync) {
                    toast('업데이트가 완료 되었습니다.');
                }
            }
        });
    }


    onChangeShop = async (event) => {
        const { name, value } = event.target;
        let shopNo = value ? parseInt(value) : 1;
        this.store.shopNo = shopNo;
    };

    onChangeInputs = async (event, targetObj) => {
        let { name, value } = event.target;
        if(targetObj) {
            targetObj[name] = value;
        }
    };


    shopNSkinInstall = async (item) => {

        let res = await this.props.shopNSkinInstall({});
        console.log(res);
    };


    render() {


        return (
            <>
                <div className="main-content">
                    <PageTitle />

                    <div onClick={e => this.shopNSkinInstall()}>업로드 테스트</div>
                    {( this.isLogin && this.teamUser.emailId ) ?
                        <div className="write-post-box">

                            {/* 기본 설정 */}
                            <div className="section-box" id="basicWriteSettings">
                                <div className="substitute-table">

                                    <div className="section-tit">
                                        <h3>멀티샵 선택하기</h3>
                                    </div>
                                    <div className="section-cont">
                                        <FormSelect value={this.store.shopNo} name="shopNo"
                                                    onChange={event => {
                                                        this.onChangeShop(event, this.store)
                                                    }}
                                        >
                                            {this.shops.map((item, i) => {
                                                return (<option key={i} i={i} value={item.platformShopNo}>{item.shopName}</option>);
                                            })}
                                        </FormSelect>
                                    </div>

                                    <div className="section-tit mt-3">
                                        <h3>제품 상세 페이지</h3>
                                        {/*<p className={"text-warning"}>리뷰의 신뢰성을 유지하고 허위 리뷰로 오인되지 않도록 신중하게 설정해 주세요. 리뷰 노출 설정(이름 및 날짜 비공개 등)에 대한 책임은 전적으로 고객에게 있으며, 당사는 이에 대한 책임을 지지 않습니다.</p>*/}
                                    </div>
                                    <table className="s-table">
                                        <colgroup>
                                            <col width="170px"/>
                                            <col width=""/>
                                            <col width="170px"/>
                                            <col/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>실시간 구매 카운트</th>
                                            <td>
                                                <FormCheckbox toggle small checked={!this.addOnSetting.isLivePurchaseCount}
                                                              onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLivePurchaseCount'])}>
                                                    {this.addOnSetting.isLivePurchaseCount ? '미노출' : '노출'}
                                                </FormCheckbox>
                                            </td>
                                            <td colSpan={2}></td>
                                        </tr>
                                        <tr>
                                            <th>실시간 장바구니 카운트</th>
                                            <td>
                                                <FormCheckbox toggle small checked={!this.addOnSetting.isLiveCartCount}
                                                              onChange={event => this.onChangeChecked(event, this.addOnSetting, ['isLiveCartCount'])}>
                                                    {this.addOnSetting.isLiveCartCount ? '미노출' : '노출'}
                                                </FormCheckbox>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="btn-save">
                                        <Button className="save-button" onClick={event => this.updateBasicSetting(event)}>저장</Button>
                                    </div>
                                </div>
                            </div>

                            <ModalLoading isLoading={this.isLoading}/>
                        </div>
                        :
                        null
                    }
                </div>


            </>
        )
    }
}

export default DesignAddOn;
