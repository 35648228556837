import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import axios from "axios";
import { decorate, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import {reactLocalStorage as Storage} from "reactjs-localstorage";

import 'moment';
import 'moment/locale/ko';
import 'moment-timezone';

import routes from "./routes";
import withTracker from "./withTracker";
import history from "./utils/history";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.css";
import "./assets/style.css";


@inject(({ common }) => ({ getSetting: common.getSetting, getIsShowPopup: common.getIsShowPopup, setIsShowPopup: common.setIsShowPopup, isShowModal: common.isShowModal  }))
@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, getRfAuth: teamUser.getRfAuth, isLogin:teamUser.isLogin, permissions:teamUser.permissions, me:teamUser.me, doLogOut:teamUser.doLogOut }))

@observer
class App extends Component {

  @observable loadingStarted = false;
  constructor(props) {
    super(props);
    if(process.env.NODE_ENV === "development") {
      axios.defaults.baseURL = 'https://orpapi.onreview.co.kr/orv';
    }
    else {
      axios.defaults.baseURL = 'https://orpapi.onreview.co.kr/orv';
    }
    //axios.defaults.baseURL = 'https://ocapi.onreview.co.kr/orv';
    //axios.defaults.baseURL = '/orv';
    axios.defaults.timeout = 60*10000;//10s
    const { getRfAuth } = this.props;

    let noneAuthRoute = ['/', '/signIn', '/signUp', '/cafe24', '/cafeRedirect', '/welcome', '/serviceAgree', '/privateAgree'];
    let cafe24AuthRoute = ['/cafe24', '/cafeRedirect'];
    axios.interceptors.response.use(function (res) {
      // console.log(noneAuthRoute.indexOf(history.location.pathname));
      // console.log(res.data.data);
      if(res && res.data && res.data.data && res.data.data.getMe && res.data.data.getMe.data && res.data.data.getMe.data.e) {
        if(res.data.data.getMe.data.e === 'null cafe24Token' || res.data.data.getMe.data.e === 'null cafe24Tokens re') {
          alert("쇼핑몰 관리자 > 앱 > '온리플'앱을 실행하여 로그인 해주세요.")
          window.location.replace('/signIn');
        }
      }

      if(noneAuthRoute.indexOf(history.location.pathname) == -1 && res.data && res.data.data && (res.data.data.getMe || res.data.data.getMe === null) ) {
        if(res.data.data.getMe === null || (!res.data.data.getMe.emailId && res.data.data.getMe.emailId != '')) {
          window.location.replace('/signIn');
        }
      }

      //todo auth 체크하여 유효하지 않으면 로그아웃
      return res;
    }, function (error) {
      let configData = JSON.parse(error.response.config.data);
      if(configData.operationName === 'getMe') {
        if(error.response && error.response.data && error.response.data.errors) {

          let errMes = error.response.data.errors[0].message;
          if(errMes === 'Context creation failed: jwt malformed') {
            //todo 잘못된 토큰 또는 토큰값이 없는경우
          }
          else if(errMes === 'Context creation failed: jwt expired') {
            getRfAuth(function(data) {
              //console.log(data);
              //todo logout => 로그인 팝업 또는 로그인 페이지로 이동...
            });
          }
        }
      }
    });

    if(cafe24AuthRoute.indexOf(history.location.pathname) == -1) {
      if(Storage.get('token')) {
        if(history.location.pathname == '/signIn') {
          this.loadingStarted = true;
        }
        else {
          this.init();
        }
      }
      else {
        this.loadingStarted = true;
        if(noneAuthRoute.indexOf(history.location.pathname) == -1) {
          window.location.replace('signIn');
        }
      }
    }
    else {
      this.loadingStarted = true;
    }

  }


  init = async () => {
    await this.props.authCheck();
    this.loadingStarted = true;
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || "/"}>
        {this.loadingStarted ? (
            <>
              {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        history={history}
                        component={withTracker(props => {
                          return (
                              <route.layout {...props} route={route} getIsShowPopup={this.props.getIsShowPopup} setIsShowPopup={this.props.setIsShowPopup} >
                                <route.component {...props} route={route} />
                              </route.layout>
                          );
                        })}
                    />
                );
              })}
            </>
        ) : null}

      </Router>
    );
  }





}

export default App;
