import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
import history from "../utils/history";


export class AOA {

    @action dmUplad = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'dmUplad',
            variables: data,
            getData: 'data'
        };
        let gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation];
                }

            })
            .catch(function (error) {
                if(typeof cb === 'function') {
                    cb(error)
                }
                return error;
            });
    };


  @action getStoreList = (data, cb) => {

    let property = ['page', 'limit', 'searchParams']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getStoreList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            if(typeof cb === 'function') {
              cb(res.data.data[params.operation]);
            }
            else {
              return res.data.data[params.operation];
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };

  @action getStoreDetail = (data, cb) => {

    let property = ['store_id', 'isRefreshToken']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getStoreDetail',
      variables: variables,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            if(typeof cb === 'function') {
              cb(res.data.data[params.operation]);
            }
            else {
              return res.data.data[params.operation];
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };




  @action updateStoreMemo = (data, cb) => {

    let property = ['id', 'memo']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateStoreMemo',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };


  @action renewToken = (data, cb) => {

    let property = ['store_id']
    let variables = util.getDataStByKey(data, property);
    console.log(variables)

    let params = {
        type: 'mutation',
        operation: 'renewToken',
        variables: variables,
        getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
            let resData = res.data.data[params.operation];
            history.push('/');

        })
        .catch(function (error) {
            console.log(error);
            return error;
        });
};

  @action csLogin = (data, cb) => {

    let authCheck = this.authCheck;
    let params = {
      type: 'query',
      operation: 'csLogin',
      variables: data,
      getData: 'emailId permissions token rfToken'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors) {throw res.data.errors; }
          let resData = res.data.data[params.operation];

          Storage.set('emailId', resData.emailId);
          Storage.set('token', resData.token);
          Storage.set('rfToken', resData.rfToken);
          
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        })
        .catch(function (error) {
            console.log(error);
          //alert('아이디 또는 비밀번호가 잘못되었습니다.(아이디 또는 비밀번호 확인은 카페24의 앱을 통해 접속 후 확인해 주세요.)');
          //return error;
        });
  };

  @action withdrawal = (data, cb) => {

    let property = ['store_id', 'password', 'reason']
    let variables = util.getDataStByKey(data, property);
    console.log(variables)

    let params = {
      type: 'mutation',
      operation: 'withdrawal',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          let resData = res.data.data[params.operation];
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };

    @action unsubscribeUser = (data, cb) => {

        let property = ['store_id', 'shopNo']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'mutation',
            operation: 'unsubscribeUser',
            variables: variables,
            getData: 'data'
        };
        let gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let resData = res.data.data[params.operation];
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


  @action initReviewData = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'initReviewData',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

    @action adminCusData = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'adminCusData',
            variables: data,
            getData: 'data'
        };
        const gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation];
                }

            })
            .catch(function (error) {
                if(typeof cb === 'function') {
                    cb(error)
                }
                return error;
            });
    };



}
