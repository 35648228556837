import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, getInAppOrderList: teamUser.getInAppOrderList, updateInAppOrderByReturnUrl: teamUser.updateInAppOrderByReturnUrl }))
@observer
class CafeInAppOrderReturnUrl extends Component {

  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    Storage.remove('isInAppOrderCp');
    if(!this.urlParams.get('result') || !this.urlParams.get('order_id')) {
      alert('잘못된 접근 방식입니다. 당사의 문의 바랍니다.');
      //todo redirect
    }
    this.store = this.props.me.store ? this.props.me.store : {};
    this.teamUser = this.props.me;
    if(this.store._id) {
      if(this.urlParams.get('order_id')) {
        let orderResult = {
          platformOrderId: this.urlParams.get('order_id'),
          paySuccess: this.urlParams.get('result') === "success" ? this.urlParams.get('result') : "fail" ,
        }
        this.updateInAppOrderByReturnUrl(orderResult);
      }

    }
  };

  @action
  updateInAppOrderByReturnUrl(orderResult) {
    let _this = this;
    this.props.updateInAppOrderByReturnUrl(orderResult, function(res) {
      if(orderResult.paySuccess === "fail") {
        alert('주문 실패 하였습니다.');
        window.location.replace("/inAppOrder");
      }
      if(res && res.message === "no data platformOrderId") {
        alert('주문 데이터가 없습니다. 다시 시도해 주세요.');
        window.location.replace("/");
      }
      if(res && orderResult.paySuccess === "success") {
        window.location.replace("/inAppOrderList");
      }
      Storage.set('isInAppOrderCp', 'end');
      //http://localhost:3000/cafeInAppOrderReturnUrl?order_id=cafe24-20250318-447327123&result=failss
      //https://orvad.owphew.co.kr/cafeInAppOrderReturnUrl?order_id=cafe24-20250318-447327123&result=success
      window.close();
    });
  }

  render() {
    return (
      <>
        <div className="sign-content">
          <div className="sign-content-bg">
            <span className="bg-cont1"></span>
            <span className="bg-cont2"></span>
            <span className="bg-cont3"></span>
            <span className="bg-cont4"></span>
          </div>
        </div>
      </>
    )
  }
}

export default CafeInAppOrderReturnUrl;
